import { ConstValues } from '@/ConstValues';
import { ShippingDeliveryMethod } from '@/store/models/OrderlineModel';
import { ShippingVendorModel } from '@/store/models/RequestModels/ShippingRequestModels';
import {
  ShipmentRequestQuote,
  ShipmentRequestQuoteModel
} from '@/store/models/ShippingModels';
import axios, { AxiosResponse } from 'axios';

const shippingUrl = `${process.env.VUE_APP_ROOT_API}/Shipping`;
const shippingServiceUrl = `${process.env.VUE_APP_SHIPPING_API}/Shipping`;

export const ShippingService = {
  DeleteCustomerShippingVendor(vendorId: number): Promise<AxiosResponse> {
    return axios.get(
      `${shippingUrl}/DeleteCustomerShippingVendor?shippingVendorId=${vendorId}`
    );
  },
  GetDeliveryOptions(isInternational: boolean = false): Promise<AxiosResponse> {
    return axios.get(
      `${shippingUrl}/GetDeliveryOptions?isInternational=${isInternational}`
    );
  },
  GetPackingList(id: string): Promise<AxiosResponse> {
    return axios.post(`${shippingUrl}/GetPackingList`, { Identifier: id });
  },
  GetPackinglistByOrderlineId(id: string): Promise<AxiosResponse> {
    return axios.get(`${shippingUrl}/GetPackinglistByOrderlineId?orderlineId=${id}`);
  },
  GetShippingNotesForOrderline(orderlineId: string): Promise<AxiosResponse> {
    return axios.get(
      `${shippingUrl}/GetShippingNotesForOrderline?orderlineId=${orderlineId}`
    );
  },
  GetShippingVendors(): Promise<AxiosResponse> {
    return axios.get(`${shippingUrl}/GetShippingVendors`);
  },
  GetCustomerShippingVendors(): Promise<AxiosResponse> {
    return axios.get(`${shippingUrl}/GetCustomerShippingVendors`);
  },
  PickupPackage(
    id: string,
    name: string,
    trackingNumber: string = ''
  ): Promise<AxiosResponse> {
    return axios.post(`${shippingUrl}/PickupPackage`, {
      Identifier: id,
      Name: name,
      TrackingNumber: trackingNumber
    });
  },
  SaveCustomerShippingVendor(model: ShippingVendorModel): Promise<AxiosResponse> {
    return axios.post(`${shippingUrl}/SaveCustomerShippingVendor`, model);
  },
  ValidateBillingAddressForShipping(addressId: string, accountNumber: string) {
    const model = new ShipmentRequestQuoteModel();
    model.AccountNumber = accountNumber;
    model.BillReceiverAddressId = addressId;
    model.PublicAddressId = ConstValues.CUSTOMER_SHIP_TO_DUMMY_ID;
    model.ServiceType = 3;
    model.ShipmentRequest = [
      new ShipmentRequestQuote({
        PlNumber: '1',
        PackType: 2,
        ShipmentWeight: '1',
        Package: { Length: 8, Width: 8, Height: 8 }
      })
    ];
    return axios.post(`${shippingServiceUrl}/CreateShippingRequest`, model);
  }
};

export default ShippingService;
