import { GetterTree } from 'vuex';
import { RootState } from '../../models/RootState';
import {
  OrderlineState,
  Orderline,
  OrderlineDetails,
  OrderPo,
  ShippingDeliveryMethod,
  CustomerOrder
} from '@/store/models/OrderlineModel';
import { state } from '../profile';
import { ConstValues } from '@/ConstValues';
export const getters: GetterTree<OrderlineState, RootState> = {
  orderlines(state): Orderline[] {
    const { orderlines } = state;
    if (orderlines) {
      // return [...orderlines.filter(o => o.Page === state.currentPage).values()];
      return [...orderlines.filter(o => o.Id).values()];
    }
    return [];
  },
  queuedItems(state): Orderline[] {
    const { queuedOrderlines } = state;
    if (queuedOrderlines) {
      return [...queuedOrderlines.values()];
    }
    return [];
  },
  hasOrderlines(state): boolean {
    return state.hasOrderlines;
  },
  onHoldOrderlines(state): Orderline[] {
    const { onHoldOrderlines } = state;
    if (onHoldOrderlines) return [...onHoldOrderlines.values()];
    return [];
  },
  orderlineProofs(state): Orderline[] {
    const { orderlineProofs } = state;
    if (orderlineProofs) return [...orderlineProofs.values()];
    return [];
  },
  orderlinesWithInvoice(state): Orderline[] {
    const { orderlinesWithInvoice } = state;
    if (orderlinesWithInvoice) return [...orderlinesWithInvoice.values()];
    return [];
  },
  pendingOrderlines(state): Orderline[] {
    const { pendingOrderlines } = state;
    if (pendingOrderlines) return [...pendingOrderlines.values()];
    return [];
  },
  recentlyShippedOrderlines(state): Orderline[] {
    const { recentlyShippedOrderlines } = state;
    if (recentlyShippedOrderlines) return [...recentlyShippedOrderlines.values()];
    return [];
  },
  getCurrentPage(state): number {
    const { currentPage } = state;
    if (currentPage) {
      return currentPage;
    }
    return 1;
  },
  getCurrentOrder(state): OrderlineDetails[] {
    const { currentOrder } = state;
    return currentOrder;
  },
  getOrderlineCounts(
    state
  ): {
    allOrderlinesCount: number;
    inProductionCount: number;
    inTransitCount: number;
  } {
    const { allOrderlinesCount, inProductionCount, inTransitCount } = state;
    return { allOrderlinesCount, inProductionCount, inTransitCount };
  },
  getDeliveryMethodFromRushDay: state => (
    dayValue: number,
    isInternational: boolean
  ): ShippingDeliveryMethod => {
    const { shippingDeliveryOptions } = state;
    if (isInternational) {
      switch (dayValue) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5: {
          return (
            shippingDeliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHFORINTERNATIONALOPTIONID
            ) ?? new ShippingDeliveryMethod()
          );
        }
        default: {
          return (
            shippingDeliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTFORINTERNATIONALOPTIONID
            ) ?? new ShippingDeliveryMethod()
          );
        }
      }
    } else {
      switch (dayValue) {
        case 1:
          return (
            shippingDeliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL1DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod()
          );
        case 2:
        case 3:
          return (
            shippingDeliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL2DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod()
          );
        default:
          return (
            shippingDeliveryOptions.find(
              (d: ShippingDeliveryMethod) =>
                d.Id == ConstValues.DELIVERY_OPTIONS.DEFAULTRUSHLEVEL2DELIVERYOPTIONID
            ) ?? new ShippingDeliveryMethod()
          );
      }
    }
  },
  getLoadingState(state): boolean {
    const { isLoading } = state;
    return isLoading || false;
  },
  getOrderPos(state): OrderPo[] {
    const { pos } = state;
    if (pos) {
      return pos;
    }
    return [];
  },
  getSearchedOrderlines(state): Orderline[] {
    const { filteredOrderlines } = state;
    if (filteredOrderlines) {
      return filteredOrderlines;
    }
    return [];
  },
  getOrderSearchContent(state): string[] {
    const { orderSearch } = state;
    if (orderSearch) {
      return [...orderSearch.values()];
    }
    return [];
  },
  getPaginationStatus(state): boolean {
    const { isDonePaginating } = state;
    return isDonePaginating;
  },
  shippingDeliveryOptions: state => (
    isInternational: boolean
  ): ShippingDeliveryMethod[] => {
    const { shippingDeliveryOptions } = state;
    return shippingDeliveryOptions.filter(s => s.IsInternational == isInternational);
  },
  getShoppingCart(state): CustomerOrder {
    const { shoppingCart } = state;
    return shoppingCart;
  }
};
