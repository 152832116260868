import { ActionTree } from 'vuex';

import { RootState } from '../../models/RootState';
import DieService from '@/services/die-service';
import { AxiosResponse } from 'axios';
import { DieState, DieTemplate } from '@/store/models/DieModel';

export const actions: ActionTree<DieState, RootState> = {
  async getDieTemplates({ commit }) {
    commit('areDiesLoading', true);
    try {
      const response = await DieService.GetTemplatesListings();
      const payload: DieTemplate[] = response.data;
      commit('diesLoaded', payload);
    } catch (err) {
      console.log(err);
    }
    commit('areDiesLoading', false);
  }
};
