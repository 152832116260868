import { ActionTree } from 'vuex';
import { ProfileState, User, UserValidation } from '../../models/ProfileModel';
import { RootState } from '../../models/RootState';
import { AuthService } from '@/services/auth-service';
import { AdminService } from '@/services/admin-service';

export const actions: ActionTree<ProfileState, RootState> = {
  setUserData({ commit }, payload: User): any {
    commit('profileLoaded', payload);
  },
  setUserDataError({ commit }): any {
    commit('profileError');
  },
  login({ commit }, payload: FormData): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customerId = payload.get('customerId') as string;
        if (customerId) {
          await AdminService.impersonateCustomer(payload, this);
        } else {
          await AuthService.login(payload, this);
        }
        const localStorageUser = localStorage.getItem('user');
        if (localStorageUser) {
          const user = new User(JSON.parse(localStorageUser));
          commit('setToken', user);
        }
        resolve(localStorageUser);
      } catch (error) {
        reject(error);
      }
    });
  },
  async isAdmin({ commit }) {
    const { data: isAdmin } = await AuthService.isAdmin();
    commit('setIsAdmin', isAdmin);
  },
  logout({ commit }): any {
    commit('removeToken');
    window.location = '/' as any;
  },
  async getImpersonatedCustomer({ commit }): Promise<void> {
    const response = await AdminService.getImpersonatedCustomer();
    const localStorageUser = localStorage.getItem('user');
    if (response.data && localStorageUser) {
      let user = new User(JSON.parse(localStorageUser));
      user.impersonatedCustomer = response.data;
      commit('setToken', user);
    }
  },
  async getUserValidation({ commit }): Promise<void> {
    const isConfirmed = await AuthService.isConfirmed();
    const isAdmin = await AuthService.isAdmin();

    commit(
      'setUserValidation',
      new UserValidation({
        isAdmin: isAdmin.data,
        isConfirmed: isConfirmed.data
      })
    );
  }
};
