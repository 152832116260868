import { GetterTree } from 'vuex';
import {
  ItemsState,
  Item,
  ItemInfo,
  ItemState,
  ItemDetails
} from '../../models/ItemModel';
import { RootState } from '../../models/RootState';
import { OrderPo } from '@/store/models/OrderlineModel';
import { filter } from 'vue/types/umd';
import { state } from '../profile';
export const getters: GetterTree<ItemsState, RootState> = {
  items(state): Item[] {
    const { items } = state;
    if (items) {
      return [...items.filter(i => i.Id).values()];
    }
    return [];
  },
  queuedItems(state): Item[] {
    const { queuedItems } = state;
    if (queuedItems) {
      return [...queuedItems.values()];
    }
    return [];
  },
  filteredItem(state): Item[] {
    const { filteredItem } = state;
    if (filteredItem) {
      return [...filteredItem.filter(i => i.Id).values()];
    }
    return [];
  },
  getFilteringType(state): number {
    const { filteringType } = state;
    if (filteringType) {
      return filteringType;
    }
    return 0;
  },
  itemInfo(state): ItemInfo {
    const { itemInfo } = state;
    if (itemInfo) {
      return itemInfo;
    }
    return new ItemInfo();
  },
  getCurrentPage(state): number {
    const { currentPage } = state;
    if (currentPage) {
      return currentPage;
    }
    return 1;
  },
  getItemPartNumber(state): any[] {
    const { partNumbers } = state;
    if (partNumbers) {
      return [...partNumbers];
    }
    return [];
  },
  getFilteredItem(state): any[] {
    const { filteredItem } = state;
    if (filteredItem) {
      return filteredItem;
    }
    return [];
  },
  getItemFilterCountContainer(state): any[] {
    const { itemFilterCountContainer } = state;
    if (itemFilterCountContainer) {
      return itemFilterCountContainer;
    }
    return itemFilterCountContainer;
  },
  getLoadingState(state): boolean {
    const { isLoading } = state;
    return isLoading || false;
  },
  getShouldReconcileQueuedItems(state): boolean {
    const { shouldReconcileQueuedItems } = state;
    return shouldReconcileQueuedItems;
  },
  doesCustomerHaveItems(state): boolean {
    return state.hasItems;
  },
  doesCustomerHaveItemDetailsLoaded(state): boolean {
    const { items } = state;
    return !!items[0]?.Id;
  },
  getAllProcessingImageIds(state): string[] {
    const { processingImageItemIds } = state;
    if (processingImageItemIds) {
      return processingImageItemIds;
    }
    return [];
  },
  getErrorLoadingItems(state): boolean {
    return state.errorLoadingItems;
  },
  getPosAssociatedWithItem(state): string[] {
    const { posAssociatedWithItem } = state;
    if (posAssociatedWithItem[0]) {
      return posAssociatedWithItem;
    }
    return [];
  },
  getItemByPublicId: (state: ItemsState) => (publicId: string): any => {
    const { items } = state;
    let item = items.find(i => i.PublicId === publicId);
    return item;
  }
};
