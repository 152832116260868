import { Address, ShippingVendor } from './AddressModel';

export class CustomerShippingVendor {
  Id!: number;
  ShippingVendor!: ShippingVendor;
  AccountNum!: string;
  Address: Address;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.ShippingVendor = new ShippingVendor(rawItem.ShippingVendor);
      this.AccountNum = rawItem.AccountNum;
      this.Address = new Address(rawItem.Address);
    }
  }
}
export class ShipmentRequestQuoteModel {
  BillReceiverAddressId: string = '';
  PublicAddressId!: string;
  ShipmentRequest: ShipmentRequestQuote[] = [];
  ServiceType!: number;
  AccountNumber!: string;
}

export class ShipmentRequestQuote {
  PlNumber!: string;
  PackType!: number;
  ShipmentWeight!: number;
  Package!: Package;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.PlNumber = rawItem.PlNumber;
      this.PackType = rawItem.PackType;
      this.ShipmentWeight = rawItem.ShipmentWeight;
      this.Package = rawItem.Package;
    }
  }
}

export class PackageType {
  Id!: number;
  Length: number;
  Width: number;
  Height: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Length = parseInt(rawItem.Length);
      this.Width = parseInt(rawItem.Width);
      this.Height = parseInt(rawItem.Height);
    }
  }
}

export class Package {
  Weight!: number | undefined;
  PackageType!: PackageType;
  constructor(packageType: PackageType, weight?: number | undefined) {
    this.PackageType = packageType;
    this.Weight = weight;
  }
  GetDimensions() {
    return {
      Width: this.PackageType.Width,
      Height: this.PackageType.Height,
      Length: this.PackageType.Length
    };
  }
}
