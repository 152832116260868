import { GetterTree } from 'vuex';
import { RootState } from '@/store/models/RootState';
import { Customer, CustomerState, Snackbar } from '@/store/models/CustomerModel';
export const getters: GetterTree<CustomerState, RootState> = {
  getCustomer(state): Customer | null {
    const { customer } = state;
    if (customer) {
      return customer;
    }
    return null;
  },
  getCurrentSnackbar(state): Snackbar {
    const { snackbar } = state;
    return snackbar;
  },
  getDisplayStyle(state): string {
    const { displayStyle } = state;
    return displayStyle;
  },
  isTransparencyCustomer(state): boolean {
    const { customer } = state;
    if (customer) {
      return customer.Options?.CanUseAmazonVarData;
    }
    return false;
  }
};
