import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { OrderlineState, Orderline, CustomerOrder } from '@/store/models/OrderlineModel';

export const state: OrderlineState = {
  orderlines: [new Orderline()],
  queuedOrderlines: [new Orderline()],
  currentPage: 0,
  currentOrder: [],
  filteredOrderlines: [],
  orderlinesWithInvoice: [],
  onHoldOrderlines: [],
  orderlineProofs: [],
  recentlyShippedOrderlines: [],
  pendingOrderlines: [],
  pos: [],
  shoppingCart: new CustomerOrder(),
  orderSearch: [],
  hasOrderlines: true,
  error: false,
  isLoading: false,
  shouldReconcileQueuedOrderlines: false,
  allOrderlinesCount: 0,
  inProductionCount: 0,
  inTransitCount: 0,
  isDonePaginating: false,
  shippingDeliveryOptions: []
};

const namespaced: boolean = true;
export const orderlines: Module<OrderlineState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
