import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { ItemsState, Item, ItemInfo } from '../../models/ItemModel';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const state: ItemsState = {
  items: [new Item()],
  queuedItems: [new Item()],
  partNumbers: [],
  filteredItem: [],
  filteringType: 0,
  posAssociatedWithItem: [],
  hasItems: true,
  filteredPartNumber: [],
  itemFilterCountContainer: [],
  processingImageItemIds: [],
  itemInfo: new ItemInfo(),
  currentPage: 1,
  errorLoadingItems: false,
  isLoading: false,
  shouldReconcileQueuedItems: false,
  isDonePaginating: false
};

const namespaced: boolean = true;
export const items: Module<ItemsState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
