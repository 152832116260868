import { Color, Die, Finish, Item, Material, RollDirection } from './ItemModel';
import { Address } from './AddressModel';
import {
  IsStatusGreaterThanOrEqualToAnotherStatus,
  IsStatusLessThanAnotherStatus
} from '@/helpers/StatusHelper';
import { PrintStatusValues, RequestedOrderlineMetricTypes } from '@/ConstValues';

export class Orderline {
  TypeName: string = 'orderlines';
  Id!: string;
  Address!: Address;
  CanBeReordered!: boolean;
  CanDeleteAmazonRequest!: boolean;
  CanEditProductionData!: boolean;
  Color!: Color;
  CoreCharge: number;
  CustomerNote!: any;
  CustomerOrderId!: string;
  DatePackaged!: Date;
  DateShipped!: Date;
  Die: Die;
  DiscountType: string;
  DiscountValue: number;
  DisplayDate: Date;
  DueDate!: Date;
  EstimatedDueDate!: Date;
  ExtendedPrice: number;
  ExtendedToString: string;
  Finish!: Finish;
  GeneratedRollQuantity: number;
  HasAnError: boolean;
  HasBeenZeroedOut: boolean;
  HasInvoice!: boolean;
  IsARetention!: boolean;
  IsARush!: boolean;
  IsCustomerPickUp!: boolean;
  HandApplyFee!: number;
  IsHandApply!: boolean;
  IsQuote!: boolean;
  IsShippedOrGreater!: boolean;
  IsSelected: boolean;
  IsVisible!: boolean;
  Item!: Item;
  ItemPartNumber!: string;
  LineItemAmount!: number;
  Material!: Material;
  MaxLabelsPerRoll!: number;
  NeedsCodes: boolean;
  OD!: number;
  OrderCreationDate!: Date;
  OrderEnterDate!: Date;
  OrderCreationDateDescription!: string;
  OrderEnterDateDescription!: string;
  OrderlinePurchaseStateType!: OrderlinePurchaseStateTypes;
  OrderLineType!: OrderLineTypes;
  OrderStatus!: OrderStatus;
  OriginalDueDate!: Date;
  Page!: number;
  PO!: string;
  PriceEach!: number;
  PriceTier!: number;
  PrintStatusValue!: PrintStatusValues;
  Quantity!: number;
  QuantityPerRoll!: number;
  QuotedQuantity!: number;
  RequestedOrderlineMetricType: RequestedOrderlineMetricTypes;
  RequestedOD: number;
  RequestedRollQuantity: number;
  RequestedMaxLabelQuantity: number;
  RequestedRollDirectionId: number;
  RollDirection!: RollDirection;
  RollQuantity!: number;
  RushFee!: number;
  RushOption!: RushOption;
  ShippingAddress!: Address;
  ShippingDeliveryMethod!: ShippingDeliveryMethod;
  ShipDate!: string;
  ShipByDate!: Date;
  Status!: string;
  Total: number;
  constructor(rawItem?: any) {
    this.IsVisible = true;
    this.IsSelected = false;
    this.Populate(rawItem);
  }
  Populate = (rawItem?: any) => {
    this.Die = new Die();
    if (rawItem) {
      this.Id = rawItem.Id;
      this.CanDeleteAmazonRequest = rawItem.CanDeleteAmazonRequest;
      this.Address = new Address(rawItem.Address);
      this.CanBeReordered = rawItem.CanBeReordered;
      this.Color = new Color(rawItem.Color);
      this.CoreCharge = rawItem.CoreCharge;
      this.CustomerNote = rawItem.CustomerNote;
      this.CustomerOrderId = rawItem.OrderId;
      this.DatePackaged = rawItem.DatePackaged;
      this.DateShipped = rawItem.DateShipped;
      this.Die = new Die(rawItem.Die);
      this.DiscountValue = rawItem.DiscountValue;
      this.DiscountType = rawItem.DiscountType;
      this.DisplayDate = new Date(rawItem.DisplayDate);
      this.DueDate = rawItem.DueDate;
      this.EstimatedDueDate = new Date(rawItem.EstimatedDueDate);
      this.ExtendedPrice = rawItem.Extended;
      this.ExtendedToString = rawItem.ExtendedToString;
      this.Finish = new Finish(rawItem.Finish);
      this.GeneratedRollQuantity = rawItem.GeneratedRollQuantity;
      this.HasAnError = rawItem.HasAnError;
      this.HasBeenZeroedOut = rawItem.HasBeenZerodOut;
      this.HandApplyFee = rawItem.HandApplyFee;
      this.IsARetention = rawItem.IsARetention;
      this.IsARush = rawItem.IsARush;
      this.IsCustomerPickUp = rawItem.IsCustomerPickUp;
      this.IsHandApply = rawItem.IsHandApply;
      this.Item = new Item(rawItem.Item);
      this.ItemPartNumber = rawItem.ItemPartNumber;
      this.LineItemAmount = rawItem.LineItemAmount;
      this.Material = new Material(rawItem.Material);
      this.OD = rawItem.OD;
      this.MaxLabelsPerRoll = rawItem.MaxLabelsPerRoll;
      this.NeedsCodes = rawItem.NeedsCodes;
      this.OrderCreationDate = rawItem.OrderCreationDate;
      this.OrderEnterDate = rawItem.OrderEnterDate;
      this.OrderCreationDateDescription = rawItem.OrderCreationDateDescription;
      this.OrderEnterDateDescription = rawItem.OrderEnterDateDescription;
      this.OrderlinePurchaseStateType = rawItem.OrderlinePurchaseStateType;
      this.OrderLineType = rawItem.OrderLineType;
      this.OrderStatus = new OrderStatus(rawItem.OrderStatus);
      this.OriginalDueDate = rawItem.OriginalDueDate;
      this.Page = rawItem.Page;
      this.PO = rawItem.PO;
      this.PriceEach = rawItem.PriceEach;
      this.PriceTier = rawItem.PriceTier;
      this.Quantity = rawItem.Quantity;
      this.QuantityPerRoll = rawItem.QuantityPerRoll;
      this.RollDirection = new RollDirection(rawItem.RollDirection);
      this.RollQuantity = rawItem.RollQuantity;
      this.RushFee = rawItem.RushFee;
      this.RushOption = new RushOption(rawItem.RushOption);
      this.ShipDate = rawItem.DateShipped;
      this.ShipByDate = rawItem.ShipByDate;
      this.PrintStatusValue = rawItem.PrintStatusValue;
      this.RequestedOrderlineMetricType = rawItem.RequestedOrderlineMetricType;
      this.RequestedOD = rawItem.RequestedOD;
      this.RequestedRollQuantity = rawItem.RequestedRollQuantity;
      this.RequestedMaxLabelQuantity = rawItem.RequestedMaxLabelQuantity;
      this.RequestedRollDirectionId = rawItem.RequestedRollDirectionId;
      this.Total = rawItem.Total;
      this.ShippingAddress = new Address(rawItem.Address);
      this.ShippingDeliveryMethod = new ShippingDeliveryMethod(
        rawItem.ShippingDeliveryMethod
      );
      this.Status = rawItem.Status;
      this.CanEditProductionData = IsStatusLessThanAnotherStatus(
        rawItem.PrintStatusValue,
        PrintStatusValues.SentToPress
      );
      this.IsShippedOrGreater = IsStatusGreaterThanOrEqualToAnotherStatus(
        rawItem.PrintStatusValue,
        PrintStatusValues.Shipped
      );
      this.HasInvoice = IsStatusGreaterThanOrEqualToAnotherStatus(
        rawItem.PrintStatusValue,
        PrintStatusValues.InvoiceCreated
      );
    }
  };
}

export class RushOption {
  PublicId!: string;
  DayValue!: number;
  RushFee!: number;
  DeliveryDate!: string;
  DeliveryDateDisplay!: string;
  RushLevel!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.PublicId = rawItem.PublicId;
      this.DayValue = rawItem.DayValue;
      this.RushFee = rawItem.RushFee;
      this.RushLevel = rawItem.RushLevel;
      this.DeliveryDate = rawItem.DueDate;
      this.DeliveryDateDisplay = rawItem.DeliveryDate;
    }
  }
}

export class OrderPo {
  PO!: string;
  Page!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.PO = rawItem.PO;
      this.Page = rawItem.Page;
    }
  }
}

export class ShippingDeliveryMethod {
  Id!: number;
  Description!: string;
  Days!: number;
  DeliveryType!: string;
  IsInternational!: boolean;
  ShippingVendorId!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Description = rawItem.Description;
      this.DeliveryType = rawItem.DeliveryType;
      this.IsInternational = rawItem.IsInternational;
      this.ShippingVendorId =
        rawItem.ShippingVendor && rawItem.ShippingVendor.Id
          ? rawItem.ShippingVendor.Id
          : rawItem.ShippingVendorId;
    }
  }
}

export class OrderStatus {
  OrderStatusDisplay!: string;
  OrderStatusDescription!: string;
  IsLink: boolean;
  Action: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Populate(rawItem);
    }
  }
  Populate(rawItem: any) {
    this.OrderStatusDescription = rawItem.OrderStatusDescription;
    this.OrderStatusDisplay = rawItem.OrderStatusDisplay;
    this.IsLink = rawItem.IsLink;
    this.Action = rawItem.Action;
  }
}

export class OrderlineDetails extends Orderline {
  State!: string;
  OrderLineNumber!: number;
  HasBarcodeData!: boolean;
  SetupCosts!: number;
  VariableDataPriceEach!: number;
  ShippingCost!: number;
  RefreshAll: boolean = false;

  constructor(rawItem?: any) {
    super(rawItem);
    this.PopulateDetails(rawItem);
  }
  PopulateDetails = (rawItem?: any) => {
    if (rawItem) {
      this.Status = rawItem.Status;
      this.MaxLabelsPerRoll = rawItem.MaxLabelsPerRoll;
      this.RollQuantity = rawItem.RollQuantity;
      this.State = rawItem.State;
      this.OrderLineNumber = rawItem.OrderLineNumber;
      this.HasBarcodeData = rawItem.HasBarcodeData;
      this.SetupCosts = rawItem.SetupCosts;
      this.VariableDataPriceEach = rawItem.VariableDataPriceEach;
      this.RushFee = rawItem.RushFee;
      this.ShippingCost = rawItem.ShippingCost;
      if (rawItem.Id.includes('TEMP')) {
        this.OrderlinePurchaseStateType = OrderlinePurchaseStateTypes.Create;
      }
    }
  };
  SetData(orderline: OrderlineDetails) {
    for (const prop of Object.getOwnPropertyNames(orderline).filter(
      prop => prop !== 'constructor' && prop !== '__ob__'
    )) {
      this[prop] = orderline[prop];
    }
  }
}

export enum OrderlinePurchaseStateTypes {
  DoNothing = 0,
  Create,
  Edit,
  Delete
}

export enum OrderLineTypes {
  Print = 0,
  Part,
  Proof,
  Service,
  Subscription,
  Sample,
  All
}

export enum OrderlineType {
  allOrderlines = 0,
  filteredOrderlines
}

export class OrderlineGroup {
  Orderlines!: Orderline[];
  Page!: number;
  GroupByValue!: string;
}
export class SearchRequestModel {
  SearchValue!: any;
  TypeValue!: number;
  IsEnter!: boolean;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.SearchValue = rawItem.SearchValue;
      this.TypeValue = rawItem.TypeValue;
      this.IsEnter = rawItem.IsEnter;
    }
  }
}

export class CustomerOrder {
  CanAddToOrder!: boolean;
  Id!: string;
  IsCartOrder: boolean = false;
  PO!: string;
  DueDate!: Date;
  Orderlines: OrderlineDetails[];
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.CanAddToOrder = rawItem.CanAddToOrder;
      this.Id = rawItem.Id;
      this.IsCartOrder = rawItem.IsCartOrder;
      this.PO = rawItem.PO;
      this.DueDate = new Date(rawItem.DueDate);
      this.Orderlines = rawItem.Orderlines.map(
        (ol: any) => (ol = new OrderlineDetails(ol))
      );
    }
    if (!this.Orderlines) this.Orderlines = [];
  }
}

export class OrderBreakdown {
  TotalItems!: number;
  ExpeditedItems!: number;
  DieTemplates!: number;
  Materials!: number;
  Finishes!: number;
  ExtendedPrice!: number;
  SetUpFee!: number;
  RushFee!: number;
  VariableDataFee!: number;
  ExtraRollFee!: number;
  ShippingFee!: number;
  Subtotal!: number;
  HandApplyFee: number;
  DiscountType: string;
  DiscountValue: number;
  constructor() {
    this.ExtendedPrice = 0;
    this.HandApplyFee = 0;
    this.SetUpFee = 0;
    this.RushFee = 0;
    this.VariableDataFee = 0;
    this.ExtraRollFee = 0;
    this.ShippingFee = 0;
    this.Subtotal = 0;
    this.DiscountValue = 0;
    this.DiscountType = '';
  }
}

export class Quote {
  IsQuote!: boolean;
  QuotedQuantity!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.IsQuote = rawItem.IsQuote;
      this.QuotedQuantity = rawItem.QuotedQuantity;
    }
  }
}

export interface OrderlineState {
  orderlines: Orderline[];
  onHoldOrderlines: Orderline[];
  recentlyShippedOrderlines: Orderline[];
  pendingOrderlines: Orderline[];
  orderlineProofs: Orderline[];
  queuedOrderlines: Orderline[];
  orderlinesWithInvoice: Orderline[];
  filteredOrderlines: Orderline[];
  currentPage: number;
  currentOrder: OrderlineDetails[];
  pos: OrderPo[];
  shoppingCart: CustomerOrder;
  hasOrderlines: boolean;
  orderSearch: string[];
  allOrderlinesCount: number;
  inProductionCount: number;
  inTransitCount: number;
  shouldReconcileQueuedOrderlines: boolean;
  error: boolean;
  isLoading: boolean;
  isDonePaginating: boolean;
  shippingDeliveryOptions: ShippingDeliveryMethod[];
}
