import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { ProfileState, User, UserValidation } from '../../models/ProfileModel';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import axios from 'axios';
import { ConfirmationDialogInfo } from '@/store/models/CustomerModel';

const user = localStorage.getItem('user');
if (user) {
  var storageUser = JSON.parse(user) as User;
  axios.defaults.headers.common['Authorization'] = `Bearer ${storageUser.sessionId}`;
} else {
  storageUser = {} as User;
}

export const state: ProfileState = {
  user: undefined,
  error: false,
  confirmationDialog: new ConfirmationDialogInfo(),
  storageUser,
  userValidation: new UserValidation()
};

const namespaced: boolean = true;
export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
