import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '../models/RootState';
import { profile } from './profile/index';
import { items } from './items/index';
import { dies } from './dies/index';
import { orderlines } from './orderlines/index';
import { customer } from './customer/index';
import { invoices } from './invoices/index';
Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    profile,
    items,
    dies,
    orderlines,
    customer,
    invoices
  }
};

export default new Vuex.Store<RootState>(store);
