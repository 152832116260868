import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { Customer, CustomerState, Snackbar } from '@/store/models/CustomerModel';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const state: CustomerState = {
  customer: new Customer(),
  snackbar: new Snackbar(),
  displayStyle: 'list'
};

const namespaced: boolean = true;
export const customer: Module<CustomerState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
