import { MutationTree } from 'vuex';
import { CustomerState, Customer, Snackbar } from '@/store/models/CustomerModel';
export const mutations: MutationTree<CustomerState> = {
  setCustomer(state, payload: Customer) {
    state.customer = payload;
  },
  setSnackBar(state, payload: Snackbar) {
    state.snackbar = payload;
  },
  setDisplayStyle(state, payload: string) {
    state.displayStyle = payload;
  }
};
