export class PackingList {
  Id!: number;
  DateComplete!: string;
  DateShipped!: string;
  IsCompleted!: boolean;
  ShipToId!: number;
  ShipToValue!: string;
  ShippingAddress!: string;
  ShippingVendorDesc!: string;
  ShippingVendorId!: number;
  TrackingNumber!: string;
  WasPickedUp!: boolean;
  constructor(rawPackingList?: any) {
    this.Populate(rawPackingList);
  }
  Populate(rawPackingList: any) {
    if (rawPackingList) {
      this.Id = rawPackingList.Id;
      this.DateComplete = rawPackingList.DateComplete;
      this.DateShipped = rawPackingList.DateShipped;
      this.IsCompleted = rawPackingList.IsCompleted;
      this.ShipToId = rawPackingList.ShipToId;
      this.ShipToValue = rawPackingList.ShipToValue;
      this.ShippingAddress = rawPackingList.ShippingAddress;
      this.ShippingVendorDesc = rawPackingList.ShippingVendorDesc;
      this.ShippingVendorId = rawPackingList.ShippingVendorId;
      this.TrackingNumber = rawPackingList.TrackingNumber;
      this.WasPickedUp = rawPackingList.WasPickedUp;
    }
  }
}
