import { ConfirmationDialogInfo } from '@/store/models/CustomerModel';
import { GetterTree } from 'vuex';
import { state } from '.';
import { ProfileState, User, UserValidation } from '../../models/ProfileModel';
import { RootState } from '../../models/RootState';
export const getters: GetterTree<ProfileState, RootState> = {
  isAuthenticated(state): boolean {
    const { storageUser } = state;
    if (storageUser && storageUser.isAuthenticated) {
      return true;
    }
    return false;
  },
  isAdmin(state): boolean {
    const { userValidation } = state;
    return userValidation.isAdmin;
  },
  displayName(state): string {
    const { storageUser } = state;
    return (storageUser && storageUser.displayName) || 'No Value';
  },
  user(state): User {
    const { storageUser } = state;
    return storageUser;
  },
  impersonatedUser(state): string {
    const { storageUser } = state;
    return (storageUser && storageUser.impersonatedCustomer) || '';
  },
  getUserValidation(state): UserValidation {
    const { userValidation } = state;
    return userValidation;
  },
  getCurrentConfirmationDialog(state): ConfirmationDialogInfo {
    const { confirmationDialog } = state;
    return confirmationDialog;
  }
};
