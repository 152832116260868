import { MutationTree } from 'vuex';
import { DieState, DieTemplate } from '../../models/DieModel';
export const mutations: MutationTree<DieState> = {
  areDiesLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  diesLoaded(state, payload: DieTemplate[]) {
    state.dieTemplates = payload;
  }
};
