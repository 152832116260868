import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
import { DieTemplate, DieRequestModel } from '@/store/models/DieModel';
import { QuotesOrderModel } from '@/store/models/QuotesModel';

const dieUrl = `${process.env.VUE_APP_ROOT_API}/Die`;
// const dieUrl = 'http://localhost:59410/api/Die';

export const DieService = {
  AddNewDie(model: DieRequestModel): Promise<AxiosResponse> {
    //not being used
    return axios.post(`${dieUrl}/AddNewDie`, model);
  },
  CheckIfDieExists(model: DieRequestModel): Promise<AxiosResponse> {
    // not being used
    return axios.post(`${dieUrl}/CheckIfDieExists`, model);
  },
  async DownloadDieTemplates(die: any) {
    const response = await axios({
      url: `${dieUrl}/DownloadDieTemplates?tag=${die.Tag}`,
      method: 'GET',
      responseType: 'blob'
    });
    const fileName = die.Tag + '.ai';
    if (response.data) downloadFile(response.data, fileName);
  },
  GenerateNewDie(model: DieRequestModel): Promise<AxiosResponse> {
    //not being used
    return axios.post(`${dieUrl}/GenerateNewDie`, model);
  },
  GetTemplatesListings(): Promise<AxiosResponse> {
    return axios.get(`${dieUrl}/GetTemplatesListings`);
  },
  GetDieSearch(shape: string, height?: number, width?: number): Promise<AxiosResponse> {
    //not being used
    return axios.get(
      `${dieUrl}/GetDieSearch?shape=${shape}&height=${height}&width=${width}`
    );
  },
  GetBestFitDieDimensionsByDie(dieId: number): Promise<AxiosResponse> {
    //not being used
    return axios.get(`${dieUrl}/GetBestFitDieDimensionsByDie/${dieId}`);
  },
  GetBestFitDieDimensionsByHeightAndWidth(
    height: string,
    width: string
  ): Promise<AxiosResponse> {
    return axios.get(
      `${dieUrl}/GetBestFitDieDimensionsByHeightAndWidth?height=${height}&width=${width}`
    );
  },
  GetDieSearchByHeight(shape: string, height: number): Promise<AxiosResponse> {
    //not being used
    return axios.get(`${dieUrl}?shape=${shape}&height=${height}`);
  },
  GetDefaultSvg(
    height: number,
    width: number,
    cornerRadius: number
  ): Promise<AxiosResponse> {
    return axios.get(
      `${dieUrl}/GetDefaultSvg?height=${height}&width=${width}&cornerRadius=${cornerRadius}`
    );
  },

  GetDieIdByHeightWidthAndShape(
    height: number,
    width: number,
    shape: string
  ): Promise<AxiosResponse> {
    return axios.get(
      `${dieUrl}/GetDieIdByHeightWidthAndShape?height=${height}&width=${width}&shape=${shape}`
    );
  },
  ProcessDieOrder(model: QuotesOrderModel): Promise<AxiosResponse> {
    return axios.post(`${dieUrl}/ProcessDieOrder`, model);
  }
};

export default DieService;
