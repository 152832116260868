import { ConfirmationDialogInfo, FramePricingOptionTypes } from './CustomerModel';
export class User {
  sessionId!: string;
  displayName!: string;
  isAuthenticated!: boolean;
  isImpersonating!: boolean;
  impersonatedCustomer!: string;
  userName!: string;
  FirstName!: string;
  LastName!: string;
  constructor(rawItem: any) {
    if (rawItem) {
      this.sessionId = rawItem.sessionId;
      this.displayName = rawItem.displayName;
      this.userName = rawItem.displayName;
      this.isAuthenticated = rawItem.isAuthenticated;
      this.isImpersonating = rawItem.isImpersonating;
      this.impersonatedCustomer = rawItem.ImpersonationCustomer;
    }
  }
}
export class AddUser {
  UserName!: string;
  FirstName!: string;
  LastName!: string;
  Password!: string;
  ShouldSendCredEmail: boolean = true;
  constructor() {
    this.UserName = '';
    this.FirstName = '';
    this.LastName = '';
    this.Password = '';
  }
}
export class UserValidation {
  isConfirmed!: boolean;
  isAdmin!: boolean;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.isConfirmed = rawItem.isConfirmed;
      this.isAdmin = rawItem.isAdmin;
    } else {
      this.isConfirmed = false;
      this.isAdmin = false;
    }
  }
}
export class AdminUser {
  UserName!: string;
  PublicId!: string;
  DisplayName!: string;
  HasBetaAccess!: boolean;
  IsConfirmed!: boolean;
  LastLoginDate!: string;
  IsEnabled!: boolean;
  HasLoggedIntoBeta!: boolean;
  UserLockedOutStatus!: number;
  BetaLoginDate!: string;
  Email!: string;
  CustomerId!: string;
  Password!: string;
  FirstName!: string;
  LastName!: string;
  ShouldSendConfirmationEmail!: boolean;
  IsATransparencyCustomer!: boolean;
  ShouldSendPasswordEmail!: boolean;
  ShouldOnlyCreateIdentityAccount: boolean = false;
  constructor(rawItem: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.UserName = rawItem.UserName;
      this.PublicId = rawItem.PublicId;
      this.DisplayName = rawItem.DisplayName;
      this.HasBetaAccess = rawItem.HasBetaAccess;
      this.IsConfirmed = rawItem.IsConfirmed;
      this.LastLoginDate = rawItem.LastLoginDate;
      this.IsEnabled = rawItem.IsEnabled;
      this.FirstName = rawItem.FirstName;
      this.LastName = rawItem.LastName;
      this.CustomerId = rawItem.CustomerId;
      this.HasLoggedIntoBeta = rawItem.HasLoggedIntoBeta;
    }
  }
}
export class NewCustomerModel {
  User: NewUserModel = new NewUserModel(null);
  IsTransparencyCustomer!: boolean;
  TermsId: number = 6;
  PricingModel!: number;
  ShouldAddPantonesToItem!: boolean;
  FramePricingOptionType: FramePricingOptionTypes =
    FramePricingOptionTypes.FramePricingOnly;
}
export class NewUserModel {
  UserName!: string;
  FirstName!: string;
  LastName!: string;
  Password!: string;
  CompanyName!: string;
  IsTransparencyCustomer!: boolean;
  ShouldSendConfirmationEmail!: boolean;
  ShouldSendPasswordEmail!: boolean;
  ShouldSendTransparencyWelcomeEmail!: boolean;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.UserName = rawItem.UserName;
      this.FirstName = rawItem.FirstName;
      this.LastName = rawItem.LastName;
      this.Password = rawItem.Password;
      this.CompanyName = rawItem.CompanyName;
      this.IsTransparencyCustomer = rawItem.IsTransparencyCustomer;
      this.ShouldSendConfirmationEmail = rawItem.ShouldSendConfirmationEmail;
      this.ShouldSendPasswordEmail = rawItem.ShouldSendPasswordEmail;
      this.ShouldSendTransparencyWelcomeEmail =
        rawItem.ShouldSendTransparencyWelcomeEmail;
    }
  }
  ConvertToFormData() {
    const formData = new FormData();
    formData.set('userName', this.UserName);
    formData.set('FirstName', this.FirstName);
    formData.set('LastName', this.LastName);
    formData.set('Password', this.Password);
    formData.set('CompanyName', this.CompanyName);
    formData.set('Email', this.UserName);
    return formData;
  }
}
export interface ProfileState {
  user?: User;
  error: boolean;
  storageUser: User;
  userValidation: UserValidation;
  confirmationDialog: ConfirmationDialogInfo;
}
export class CodeRequestInfo {
  CustomerName!: string;
  PO!: string;
  DueDate!: string;
  IsARush!: boolean;
  ShippingDeliveryMethod!: string;
  StatusCode!: string;
  Description!: string;
  PartNumber!: string;
  Sku!: string;
  GTIN!: string;
  ReadableText!: string;
  Asin!: string;
  Quantity!: number;
  Brand!: string;
  BrandId!: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.CustomerName = rawItem.CustomerName;
      this.PO = rawItem.PO;
      this.DueDate = rawItem.DueDate;
      this.IsARush = rawItem.IsARush;
      this.ShippingDeliveryMethod = rawItem.ShippingDeliveryMethod;
      this.StatusCode = rawItem.StatusCode;
      this.Description = rawItem.Description;
      this.PartNumber = rawItem.PartNumber;
      this.Sku = rawItem.Sku;
      this.GTIN = rawItem.GTIN;
      this.ReadableText = rawItem.ReadableText;
      this.Asin = rawItem.Asin;
      this.Quantity = rawItem.Quantity;
      this.Brand = rawItem.Brand;
      this.BrandId = rawItem.BrandId;
    }
  }
}
