import { ActionTree } from 'vuex';
import { InvoicesState, Invoice, InvoiceFilterType } from '../../models/InvoiceModel';
import { RootState } from '../../models/RootState';
import { InvoiceService } from '@/services/invoice-service';
import { handleResponse } from '@/helpers/ApiHelper';
import { getObjectDiff } from '@/helpers/ObjectHelper';
import { PaginationModel } from '@/store/models/PaginationModel';
import { ReplaceInvoiceInStoreAndRefresh } from '@/helpers/StoreHelper';

export const actions: ActionTree<InvoicesState, RootState> = {
  async GetInvoices({ commit, dispatch, state }, model: PaginationModel): Promise<any> {
    if (model.ShouldLoad) commit('setInvoicesLoading', true);
    const response = await InvoiceService.GetInvoicesForPagination(model);
    let payload: Invoice[] = response;
    if (model.CurrentPage > 1) {
      payload = [...state.invoices, ...payload];
    }
    commit('setInvoices', payload);
    commit('setInvoicesLoading', false);
    commit('setHasInvoices', payload.length > 0);
  },
  async refreshInvoice({ commit, state }, payload: string): Promise<any> {
    try {
      const { invoices } = state;
      const response = await InvoiceService.GetInvoiceById(payload);
      handleResponse(response);
      var newInvoice = new Invoice(response.data);
      ReplaceInvoiceInStoreAndRefresh(this, invoices, newInvoice);
    } catch (err) {
      console.log(err);
    }
  },
  async GetFilteredInvoices({ commit, dispatch }, model: PaginationModel): Promise<any> {
    if (model.ShouldLoad) commit('setInvoicesLoading', true);
    const response = await InvoiceService.GetInvoicesForPagination(model);
    const payload: Invoice[] = response;
    commit('setFilteredInvoices', payload);
    commit('setInvoicesLoading', false);
    commit('setHasFilteredInvoices', payload.length > 0);
  },
  ClearSearchInvoices({ commit }) {
    commit('setFilteredInvoices', []);
  }
};
