import { MutationTree } from 'vuex';
import {
  OrderlineState,
  Orderline,
  OrderlineDetails,
  OrderPo,
  OrderLineTypes,
  ShippingDeliveryMethod,
  CustomerOrder
} from '@/store/models/OrderlineModel';
export const mutations: MutationTree<OrderlineState> = {
  addPOToList(state, payload: OrderPo) {
    state.pos.unshift(payload);
  },
  areOrderlinesLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  orderlinesLoaded(state, payload: Orderline[]) {
    state.orderlines = payload;
  },
  setQueuedOrderlines(state, payload: Orderline[]) {
    state.queuedOrderlines = [...state.queuedOrderlines, ...payload];
  },
  removeQueuedOrderlines(state) {
    state.queuedOrderlines = [];
  },
  setOnHoldOrderlines(state, payload: Orderline[]) {
    state.onHoldOrderlines = payload;
  },
  setRecentlyShippedOrderlines(state, payload: Orderline[]) {
    state.recentlyShippedOrderlines = payload;
  },
  setOrderlinesWithInvoice(state, payload: Orderline[]) {
    state.orderlinesWithInvoice = payload;
  },
  setOrderlineProofs(state, payload: Orderline[]) {
    state.orderlineProofs = payload;
  },
  setPendingOrderlines(state, payload: Orderline[]) {
    state.pendingOrderlines = payload;
  },
  shouldReconcileQueuedOrderlines(state, payload: boolean) {
    state.shouldReconcileQueuedOrderlines = payload;
  },
  setCurrentPage(state, payload: number) {
    state.currentPage = payload;
  },
  setCounts(state, { TotalRecords, InProductionCount, InTransitCount }) {
    state.allOrderlinesCount = TotalRecords;
    state.inProductionCount = InProductionCount;
    state.inTransitCount = InTransitCount;
  },
  setCurrentOrder(state, payload: OrderlineDetails[]) {
    state.currentOrder = payload;
    localStorage.removeItem('currentOrder');
    localStorage.setItem('currentOrder', JSON.stringify(payload));
  },
  setDeliveryOptions(state, payload: ShippingDeliveryMethod[]) {
    state.shippingDeliveryOptions = payload;
  },
  setHasOrderlines(state, payload: boolean) {
    state.hasOrderlines = payload;
  },
  setOrderConfirmation(state, payload: any) {
    localStorage.removeItem('orderConfirmation');
    localStorage.setItem('orderConfirmation', JSON.stringify(payload));
  },
  setPos(state, payload: OrderPo[]) {
    state.pos = payload;
  },
  setFilteredOrderline(state, payload: Orderline[]) {
    state.filteredOrderlines = payload;
  },
  setPaginationState(state, payload: boolean) {
    state.isDonePaginating = payload;
  },
  setOrderSearch(state, payload: any[]) {
    state.orderSearch = payload;
  },
  refreshOrderlinesByParameter(state, payload: OrderlineDetails[]) {
    let orderlines = state.orderlines;
    for (let ol of payload) {
      let index = orderlines.findIndex(o => o.Id === ol.Id);
      if (index > -1) {
        orderlines[index] = ol;
      }
    }
    state.orderlines = orderlines;
  },
  setShoppingCart(state, payload: CustomerOrder) {
    state.shoppingCart = payload;
  },
  setOrderlinesInShoppingCart(state, payload: OrderlineDetails[]) {
    state.shoppingCart.Orderlines = payload;
  }
};
