import { GetterTree } from 'vuex';
import { RootState } from '../../models/RootState';
import { DieState, DieTemplate } from '@/store/models/DieModel';
export const getters: GetterTree<DieState, RootState> = {
  dieTemplates(state): DieTemplate[] {
    const { dieTemplates } = state;
    if (dieTemplates) {
      return [...dieTemplates];
    }
    return [new DieTemplate()];
  },
  areDiesLoading(state): boolean {
    const { isLoading } = state;
    return isLoading;
  },
  getDieById: (state: DieState) => (id: number): any => {
    const { dieTemplates } = state;
    const die = dieTemplates.find(d => d.Id === id);
    return die;
  }
};
