import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { DieState, DieTemplate } from '../../models/DieModel';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const state: DieState = {
  dieTemplates: [new DieTemplate()],
  error: false,
  isLoading: false
};

const namespaced: boolean = true;
export const dies: Module<DieState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
