import { Module } from 'vuex';
import { RootState } from '../../models/RootState';
import { InvoicesState, Invoice, InvoiceFilterType } from '@/store/models/InvoiceModel';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const state: InvoicesState = {
  invoices: [new Invoice()],
  filteredInvoices: [new Invoice()],
  filteringType: InvoiceFilterType.Everything,
  currentPage: 1,
  error: false,
  isLoading: false,
  isDonePaginating: false,
  hasInvoices: false,
  hasFilteredInvoices: false
};
const namespaced: boolean = true;
export const invoices: Module<InvoicesState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
