import { ActionTree } from 'vuex';
import { RootState } from '@/store/models/RootState';
import { Customer, CustomerState, Snackbar } from '@/store/models/CustomerModel';
import { CustomerService } from '@/services/customer-service';

export const actions: ActionTree<CustomerState, RootState> = {
  async getCurrentCustomer({ commit }) {
    try {
      const response = await CustomerService.GetCurrentCustomer();
      let customer = new Customer(response.data);
      commit('setCustomer', customer);
    } catch (err) {
      console.log(err);
    }
  },
  setSnackBarData({ commit }, payload: Snackbar) {
    commit('setSnackBar', payload);
  }
};
