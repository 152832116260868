import { ConfirmationDialogInfo } from '@/store/models/CustomerModel';
import { MutationTree } from 'vuex';
import { ProfileState, User, UserValidation } from '../../models/ProfileModel';
export const mutations: MutationTree<ProfileState> = {
  profileLoaded(state, payload: User) {
    state.error = false;
    state.user = payload;
  },
  profileError(state) {
    state.error = true;
    state.user = undefined;
  },
  setToken(state, payload: any) {
    state.storageUser = payload;
    state.error = false;
  },
  removeToken(state) {
    state.storageUser = {} as User;
    localStorage.removeItem('user');
  },
  setUserValidation(state, payload: UserValidation) {
    state.userValidation = payload;
  },
  setIsAdmin(state, payload: boolean) {
    state.userValidation.isAdmin = payload;
  },
  setConfirmationAction(state, payload: ConfirmationDialogInfo) {
    state.confirmationDialog = payload;
  }
};
