import { GetterTree } from 'vuex';
import { InvoicesState, Invoice } from '@/store/models/InvoiceModel';
import { RootState } from '../../models/RootState';
import { filter } from 'vue/types/umd';
import { state } from '../profile';
import { invoices } from '.';
export const getters: GetterTree<InvoicesState, RootState> = {
  filteredInvoices(state): Invoice[] {
    const { filteredInvoices } = state;
    if (filteredInvoices) {
      return [...filteredInvoices.filter(i => i.Id).values()];
    }
    return [];
  },
  invoices(state): Invoice[] {
    const { invoices } = state;
    if (invoices) {
      return [...invoices.filter(i => i.Id).values()];
    }
    return [];
  },
  getFilteringType(state): number {
    const { filteringType } = state;
    if (filteringType) {
      return filteringType;
    }
    return 0;
  },
  getCurrentPage(state): number {
    const { currentPage } = state;
    if (currentPage) {
      return currentPage;
    }
    return 1;
  },
  getLoadingState(state): boolean {
    const { isLoading } = state;
    return isLoading || false;
  },
  getHasInvoices(state): boolean {
    const { hasInvoices } = state;
    return hasInvoices || false;
  }
};
