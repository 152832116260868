import { ActionTree } from 'vuex';
import { ItemsState, Item, ItemInfo, ItemFilterType } from '../../models/ItemModel';
import { RootState } from '../../models/RootState';
import ItemService from '@/services/item-service';
import { OrdersService } from '@/services/orders-service';
import { ReplaceItemInStoreAndRefresh } from '@/helpers/StoreHelper';
import { handleResponse } from '@/helpers/ApiHelper';
import { getObjectDiff, areListsDifferent } from '@/helpers/ObjectHelper';
import { PaginationModel } from '@/store/models/PaginationModel';

export const actions: ActionTree<ItemsState, RootState> = {
  async refreshItemFilterCount({ commit, state }) {
    const response = await ItemService.GetItemFilterCount();
    commit('setItemFilterCountContainer', response.data);
  },
  async GetCustomerItemsLimited({ commit, state }): Promise<any> {
    commit('areItemsLoading', true);
    const { data: loadedPartNumbers } = await ItemService.GetCustomerPartNumbers();
    if (!loadedPartNumbers.paginatedPartNumber[0]) {
      commit('setHasItems', false);
    } else {
      commit('setHasItems', true);
      commit('setPartNumbers', loadedPartNumbers.paginatedPartNumber);
    }
    commit('areItemsLoading', false);
  },
  async GetItemIdsThatHaveImagesBeingProcessed({ commit }, items: Item[]) {
    items = items.filter(i => i.Die.Id !== 212 && i.ApprovalStatus !== 'Temporary');
    const processingItemId = [];
    for (let i = 0; i < items.length; i++) {
      if (!items[i].HasBeenProcessed) {
        processingItemId.unshift(items[i].Id);
      }
    }
    commit('setProcessingImageItemId', processingItemId);
  },
  async GetOnOrderItem({ commit }) {
    commit('setFilteredItem', []);
    try {
      const response = await ItemService.GetAllOnOrderCustomerItemPartNumbers();
      let itemIds = response.data.map((i: any) => i.Id);
      commit('setFilteredPartNumber', itemIds);
      const payload = await ItemService.GetItemsNoPagination(itemIds, 1);
      commit('setFilteringType', ItemFilterType.OnOrder);
      commit('setFilteredItem', payload.data);
    } catch (err) {
      commit('setErrorLoadingItems', true);
    }
  },
  async GetOnHoldItem({ commit }) {
    commit('setFilteredItem', []);
    try {
      const response = await ItemService.GetAllOnHoldCustomerItemPartNumbers();
      let itemIds = response.data.map((i: any) => i.Id);
      commit('setFilteredPartNumber', itemIds);
      const payload = await ItemService.GetItemsNoPagination(itemIds, 1);
      commit('setFilteringType', ItemFilterType.OnHold);
      commit('setFilteredItem', payload.data);
    } catch (err) {
      commit('setErrorLoadingItems', true);
    }
  },
  async GetSignOffItem({ commit }) {
    commit('setFilteredItem', []);
    try {
      const response = await ItemService.GetAllSignOffCustomerItemPartNumbers();
      let itemIds = response.data.paginatedPartNumber.map((i: any) => i.Id);
      commit('setFilteredPartNumber', itemIds);
      const payload = await ItemService.GetItemsNoPagination(itemIds, 1);
      commit('setFilteringType', ItemFilterType.SignOffs);
      commit('setFilteredItem', payload.data);
    } catch (err) {
      commit('setErrorLoadingItems', true);
    }
  },
  async GetItems({ commit, dispatch, state }, model: PaginationModel): Promise<any> {
    if (model.ShouldLoad) commit('areItemsLoading', true);
    try {
      let { partNumbers, currentPage } = state;
      if (!partNumbers[0]) {
        await dispatch('GetCustomerItemsLimited');
        partNumbers = state.partNumbers;
      }
      const itemIds = partNumbers.filter(p => p.Page === currentPage).map(p => p.Id);
      if (!itemIds[0]) {
        commit('areItemsLoading', false);
        return;
      }
      const { data } = await ItemService.GetItemsNoPagination(itemIds, currentPage);
      const payload: Item[] = data.map((r: any) => new Item(r));
      if (model.CurrentPage === 1)
        dispatch('GetItemIdsThatHaveImagesBeingProcessed', payload);

      commit('setQueuedItems', payload);
      dispatch('ShouldReconcileItems', {
        payload,
        shouldReconcile: model.ShouldAutoReconcile
      });
    } catch (err) {
      commit('setErrorLoadingItems', true);
    }
    commit('areItemsLoading', false);
  },
  async GetItemInfo({ commit }) {
    const { data } = await ItemService.GetItemTypesForItemEdit();
    commit('itemInfoLoaded', new ItemInfo(data));
  },
  ShouldReconcileItems({ commit, state, dispatch }, { payload, shouldReconcile }): void {
    let { items, currentPage } = state;
    items = items.filter(i => i.Page === currentPage);
    if (payload[0] && payload[0].Page !== currentPage) return;
    let shouldReplace = areListsDifferent(items, payload);
    if (shouldReplace) {
      if (shouldReconcile) {
        dispatch('ReconcileItems');
      } else {
        commit('shouldReconcileQueuedItems', true);
      }
    }
  },
  ReconcileItems({ commit, state }): void {
    commit('areItemsLoading', true);
    let { items, queuedItems, currentPage } = state;
    queuedItems = queuedItems.filter(i => i.Page === currentPage && i.Id !== null);
    let newItems = [...items.filter(i => i.Page !== currentPage && i.Id), ...queuedItems];
    commit('shouldReconcileQueuedItems', false);
    commit('itemsLoaded', newItems);
    commit('areItemsLoading', false);
  },
  async PlaceOnHold({ commit, state, dispatch }, payload: string): Promise<any> {
    const { items } = state;
    const response = await ItemService.PlaceItemOnHold(payload);
    ReplaceItemInStoreAndRefresh(this, items, new Item(response.data));
    dispatch('refreshItemFilterCount');
  },
  async refreshItem({ commit, state, dispatch }, payload: string): Promise<any> {
    try {
      const { items } = state;
      const response = await ItemService.RefreshItem(payload);
      handleResponse(response);
      ReplaceItemInStoreAndRefresh(this, items, new Item(response.data), false, payload);
      dispatch('refreshItemFilterCount');
    } catch (err) {
      console.log(err);
    }
  },
  async DeleteItem({ commit, state, dispatch }, payload: string): Promise<any> {
    const response = await ItemService.DeleteItem(payload);
    if (response.status === 200) {
      let { items } = state;
      const returnValue = items.filter(i => i.Id !== payload);
      commit('itemsLoaded', returnValue);
      dispatch('refreshItemFilterCount');
    }
  },
  async ApproveItem({ commit, state, dispatch }, payload: string): Promise<any> {
    try {
      const { items, filteringType } = state;
      const response = await ItemService.ApproveItemForPrinting(payload);

      handleResponse(response);
      ReplaceItemInStoreAndRefresh(
        this,
        items,
        new Item(response.data),
        filteringType == ItemFilterType.OnHold
      );
      dispatch('refreshItemFilterCount');
    } catch (err) {
      console.log(err);
    }
  },
  addProcessImageItemId({ commit, state }, payload: any) {
    const { processingImageItemIds } = state;
    processingImageItemIds.push(payload);
    commit('setProcessingImageItemId', processingImageItemIds);
  },
  removeProcessImageItemId({ commit, state, dispatch }, payload: string) {
    const { processingImageItemIds } = state;
    commit('saveImageData', { Id: payload, Stream: '' });
    let index = processingImageItemIds.findIndex(id => id === payload);
    processingImageItemIds.splice(index, 1);
    commit('setProcessingImageItemId', processingImageItemIds);
  },
  async checkForAssociatedOrderlineByItemId({ commit, state, dispatch }, itemId: string) {
    commit('setOrderlinesAssociatedWithItem', []);

    const response = await OrdersService.GetOrderlinesByItemId(itemId);
    if (response.data) {
      let orderlinesAssociatedWithThisItem = response.data;
      let result = orderlinesAssociatedWithThisItem
        .filter((o: any) => o.Status == 'OPEN' || o.Status === 'PENDING')
        .map((ol: any) => ol.PO);
      commit('setOrderlinesAssociatedWithItem', result);
    }
  },
  async checkForExistingProofOrderlineByItemId(
    { commit, state, dispatch },
    itemId: string
  ) {
    commit('setOrderlinesAssociatedWithItem', []);
    const response = await OrdersService.GetOrderlinesByItemId(itemId);
    if (response.data) {
      let orderlinesAssociatedWithThisItem = response.data;
      let result = orderlinesAssociatedWithThisItem
        .filter((o: any) => o.Status != 'CLOSED' && o.OrderLineType == 2)
        .map((ol: any) => ol.PO);
      commit('setOrderlinesAssociatedWithItem', result);
    }
  },
  async GetSearchedItemsByEntity({ commit, state }, model: PaginationModel) {
    const { filteredItem } = state;
    commit('areItemsLoading', true);
    switch (model.SearchModel.TypeValue) {
      case 0: {
        commit('setPaginationState', false);
        model.Take = 10;
        let itemRequestModel = {
          Query: model.SearchModel.SearchValue,
          Start: model.CurrentPage * model.Take - model.Take,
          Take: model.Take,
          Skip: model.CurrentPage * model.Take - model.Take
        };
        const response = await ItemService.GetItemsByPartNumberOrDescriptionForPagination(
          itemRequestModel
        );
        commit('setPaginationState', response.data.length == 0);
        let returnValue = [
          ...filteredItem,
          ...response.data.map((r: Item) => new Item(r))
        ];
        commit('setFilteredItem', returnValue);
        commit('areItemsLoading', false);

        break;
      }
      case 1: {
        commit('setPaginationState', false);
        const data = {
          Query: model.SearchModel.SearchValue,
          Skip: 0,
          Take: 100
        };
        const response = await ItemService.GetItemsByPOAndCustId(data);
        let returnValue = [...response.data.map((r: Item) => new Item(r))];
        commit('setFilteredItem', returnValue);
        commit('areItemsLoading', false);

        break;
      }
      default: {
        commit('setPaginationState', false);
        const data = {
          Query: model.SearchModel.SearchValue,
          Start: model.CurrentPage * model.Take - model.Take,
          Take: model.Take,
          Skip: model.CurrentPage * model.Take - model.Take
        };
        const response = await ItemService.GetItemsBySearchQuery(data);
        let returnValue = [...response.data.map((r: Item) => new Item(r))];
        // commit('setPaginationState', response.data.length == 0);
        commit('setFilteredItem', returnValue);
        commit('areItemsLoading', false);
      }
    }
  },
  ClearSearchOrder({ commit }) {
    commit('setFilteredItem', []);
  }
};
