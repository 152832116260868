import { RootState } from '../../models/RootState';
import {
  ReplaceOrderline,
  ReplaceOrderlineInStoreAndRefresh
} from '@/helpers/StoreHelper';
import { PaginationModel } from '@/store/models/PaginationModel';
import {
  OrderlineState,
  Orderline,
  SearchRequestModel,
  OrderlineType,
  OrderlineDetails,
  ShippingDeliveryMethod,
  CustomerOrder
} from '@/store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
import {
  areListsDifferent,
  GetUniqueElementsById,
  SortByDate
} from '@/helpers/ObjectHelper';

import { handleResponse } from '@/helpers/ApiHelper';
import { Item } from '@/store/models/ItemModel';
import { ActionTree } from 'vuex';
import { extend } from 'vue/types/umd';
import ShippingService from '@/services/shipping-service';
import { CalculatePrice } from '@/helpers/OrderlineHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AmazonRequest } from '@/store/models/AmazonAccessModels';
import { CalcOrderlineModel } from '@/store/models/RequestModels';

export const actions: ActionTree<OrderlineState, RootState> = {
  async GetOrderPos({ commit, state }): Promise<any> {
    const { data: pos } = await OrdersService.GetCustomerOrderPO();
    commit('setPos', pos);
    if (pos.length == 0) {
      commit('areOrderlinesLoading', false);
      commit('setHasOrderlines', false);
    }
  },
  async GetOrderlinePagination(
    { commit, dispatch, state },
    model: PaginationModel
  ): Promise<any> {
    if (model.ShouldLoad) commit('areOrderlinesLoading', true);
    let { pos, currentPage } = state;
    if (!pos[0] || pos.length < 2) {
      await dispatch('GetOrderPos');
      pos = state.pos;
      if (!pos[0]) return;
    }
    commit('setPaginationState', pos[pos.length - 1].Page === model.CurrentPage);

    const posToGet = pos.filter(p => p.Page === currentPage);
    const poValues = posToGet.map(v => v.PO);
    const data = {
      POs: poValues,
      CurrentPage: currentPage
    };
    const response = await OrdersService.GetOrderlinesByPO(data);
    const payload: Orderline[] = response.data.map((r: any) => new Orderline(r));
    commit('setQueuedOrderlines', payload);
    dispatch('ReconcileOrderlines');
  },
  async ReconcileOrderlines(
    { commit, dispatch, state },
    shouldUnshift = false
  ): Promise<void> {
    commit('areOrderlinesLoading', true);
    let { orderlines, queuedOrderlines, currentPage } = state;

    let newItems = shouldUnshift
      ? [...queuedOrderlines, ...orderlines]
      : [...orderlines, ...queuedOrderlines];
    let data = GetUniqueElementsById(newItems);
    data = SortByDate(data, 'OrderEnterDate');
    commit('orderlinesLoaded', data);
    commit('removeQueuedOrderlines');
    commit('shouldReconcileQueuedOrderlines', false);
    commit('areOrderlinesLoading', false);
  },
  async RefreshCustomerOrder({ state, commit }, orderId: string): Promise<void> {
    const { data } = await OrdersService.RefreshCustomerOrder(orderId);
    let payload: Orderline[] = data.map((d: any) => new Orderline(d));
    let { orderlines } = state;
    payload.forEach(ol => {
      ReplaceOrderline(orderlines, ol);
    });
    commit('orderlinesLoaded', orderlines);
  },
  RemoveOrdersByPO({ commit, state }, poToRemove: string): void {
    let { orderlines } = state;
    orderlines = orderlines.filter(ol => ol.PO !== poToRemove);
    commit('orderlinesLoaded', orderlines);
  },
  refreshMultipleOrderlines({ dispatch }, payload: string[]): void {
    payload.forEach(ol => dispatch('refreshOrderline', ol));
  },
  async refreshOrderline({ commit, state }, payload: string): Promise<any> {
    try {
      const {
        orderlines,
        recentlyShippedOrderlines,
        orderlineProofs,
        onHoldOrderlines
      } = state;
      const response = await OrdersService.RefreshOrderline(payload);
      handleResponse(response);
      var newOrderline = new OrderlineDetails(response.data);
      ReplaceOrderlineInStoreAndRefresh(this, orderlines, newOrderline);

      let recentlyShippedOrderline = recentlyShippedOrderlines.find(
        (ol: Orderline) => ol.Id === newOrderline.Id
      );
      let orderlineProof = orderlineProofs.find(
        (ol: Orderline) => ol.Id === newOrderline.Id
      );
      let onHoldOrderline = onHoldOrderlines.find(
        (ol: Orderline) => ol.Id === newOrderline.Id
      );
      if (recentlyShippedOrderline) {
        ReplaceOrderlineInStoreAndRefresh(this, recentlyShippedOrderlines, newOrderline);
        commit('setRecentlyShippedOrderlines', recentlyShippedOrderlines);
      }
      if (orderlineProof) {
        ReplaceOrderlineInStoreAndRefresh(this, orderlineProofs, newOrderline);
        commit('setOrderlineProofs', orderlineProofs);
      }
      if (onHoldOrderline) {
        ReplaceOrderlineInStoreAndRefresh(this, onHoldOrderlines, newOrderline);
        commit('setOnHoldOrderlines', onHoldOrderlines);
      }
      return newOrderline;
    } catch (err) {
      console.log(err);
    }
  },
  SetCurrentOrderInStore({ commit }, payload: Orderline[]): void {
    commit('setCurrentOrder', payload);
  },
  RefreshItemInOrderlines({ commit, state, dispatch }, payload: Item): void {
    let {
      onHoldOrderlines,
      orderlineProofs,
      recentlyShippedOrderlines,
      orderlinesWithInvoice,
      orderlines
    } = state;
    let allOrderlines = [
      onHoldOrderlines,
      orderlineProofs,
      recentlyShippedOrderlines,
      orderlinesWithInvoice,
      orderlines
    ];
    try {
      let orderlinesToChange = allOrderlines
        .flat()
        .filter(ol => ol.Item && ol.Item.Id === payload.Id);
      orderlinesToChange.forEach(ol => dispatch('refreshOrderline', ol.Id));
      commit('orderlinesLoaded', orderlines);
    } catch (err) {
      console.log(err);
    }
  },
  async GetOrderlinesThatAreOnHold({ commit }): Promise<any> {
    const response = await OrdersService.GetOrderlinesThatAreOnHold();
    commit(
      'setOnHoldOrderlines',
      response.data.map((r: any) => (r = new Orderline(r)))
    );
  },
  async GetOrderlinesRecentlyShipped({ commit }): Promise<any> {
    const response = await OrdersService.GetOrderlinesRecentlyShipped();
    commit(
      'setRecentlyShippedOrderlines',
      response.data.map((r: any) => (r = new Orderline(r)))
    );
  },
  async GetOrderlinesWithInvoice({ commit }): Promise<any> {
    const response = await OrdersService.GetCustomerOrderlinesOpenInvoice();
    commit(
      'setOrderlinesWithInvoice',
      response.data.map((r: any) => (r = new Orderline(r)))
    );
  },
  async GetOrderlineProofs({ commit }): Promise<any> {
    const response = await OrdersService.GetOrderlineProofs();
    commit(
      'setOrderlineProofs',
      response.data.map((r: any) => (r = new Orderline(r)))
    );
  },
  async GetOrderlinesPending({ commit }): Promise<any> {
    const response = await OrdersService.GetOrderlinesPending();
    commit(
      'setPendingOrderlines',
      response.data.map((r: any) => (r = new Orderline(r)))
    );
  },
  async GetAllOrderPoByCustomerId({ commit }): Promise<any> {
    const response = await OrdersService.GetAllOrderPoByCustomerId();
    commit('setOrderSearch', response.data);
  },

  async GetSearchedOrderlinesByEntity({ commit, state }, model: PaginationModel) {
    const { filteredOrderlines } = state;
    commit('areOrderlinesLoading', true);
    let returnValue = [];
    switch (model.SearchModel.TypeValue) {
      case 0: {
        commit('setPaginationState', false);
        model.Take = 10;
        let itemRequestModel = {
          Query: model.SearchModel.SearchValue,
          Start: model.CurrentPage * model.Take - model.Take,
          Take: model.Take,
          Skip: model.CurrentPage * model.Take - model.Take
        };
        const response = await OrdersService.GetOrderlinesByPartNumberOrDescriptionForPagination(
          itemRequestModel
        );
        commit('setPaginationState', response.data.length == 0);
        returnValue = [
          ...filteredOrderlines,
          ...response.data.map((r: Orderline) => new Orderline(r))
        ];

        break;
      }
      case 1: {
        commit('setPaginationState', false);
        const data = {
          POs: model.SearchModel.SearchValue,
          CurrentPage: model.CurrentPage
        };
        const response = await OrdersService.GetOrderlinesByPO(data);
        returnValue = [...response.data.map((r: Orderline) => new Orderline(r))];

        break;
      }
      default: {
        commit('setPaginationState', false);
        const data = {
          Query: model.SearchModel.SearchValue,
          Start: model.CurrentPage * model.Take - model.Take,
          Take: model.Take,
          Skip: model.CurrentPage * model.Take - model.Take
        };
        const response = await OrdersService.GetOrderlinesBySearchQuery(data);
        returnValue = [...response.data.map((r: Orderline) => new Orderline(r))];
      }
    }
    if (!returnValue[0]) returnValue.push(-1);
    commit('setFilteredOrderline', returnValue);
    commit('areOrderlinesLoading', false);
  },
  ClearSearchOrder({ commit }) {
    commit('setFilteredOrderline', []);
  },
  ClearCurrentOrder({ commit }) {
    localStorage.removeItem('currentOrder');
    commit('setCurrentOrder', []);
  },
  doesPOExist({ state }, po: string) {
    const { pos } = state;
    return pos && pos.some(p => p.PO === po);
  },
  ChangePO({ state, commit }, model: { oldPO: string; newPO: string }) {
    const { orderlines } = state;
    orderlines.map((ol: Orderline) => {
      if (ol.PO === model.oldPO) {
        ol.PO = model.newPO;
      }
    });
    commit('orderlinesLoaded', orderlines);
  },
  async getDeliveryOptions({ commit, state }) {
    if (state.shippingDeliveryOptions.length > 0) return;
    const {
      data: internationalDeliveryOptions
    } = await ShippingService.GetDeliveryOptions(true);
    const { data: deliveryOptions } = await ShippingService.GetDeliveryOptions(false);
    const allDeliveryOptions = [
      ...deliveryOptions.map(d => (d = new ShippingDeliveryMethod(d))),
      ...internationalDeliveryOptions.map(d => (d = new ShippingDeliveryMethod(d)))
    ];
    commit('setDeliveryOptions', allDeliveryOptions);
  },
  async GetShoppingCart({ commit }): Promise<any> {
    const promise = new Promise(async (resolve: any) => {
      const { data: customerOrder } = await OrdersService.GetOrderlinesInCart();
      resolve(commit('setShoppingCart', new CustomerOrder(customerOrder)));
    });
    return promise;
  },
  async AddToShoppingCart({ commit, state }, model: CalcOrderlineModel): Promise<any> {
    const promise = new Promise<void>(async (resolve, reject) => {
      try {
        let { shoppingCart } = state;
        SetSnackBar('Adding item to cart...');
        const { data } = await OrdersService.AddToCart(model);
        shoppingCart.Orderlines = [
          new OrderlineDetails(data),
          ...shoppingCart.Orderlines
        ];
        commit('setShoppingCart', shoppingCart);
        SetSnackBar('Added to cart successfully');
        resolve();
      } catch (err) {
        console.log(err);
        SetSnackBar(
          'There was an error adding to Cart, please contact techsupport@just1label.com'
        );
        reject();
      }
    });
    return promise;
  },
  async CalculatePriceOfShoppingCart(
    { state, commit },
    additionalOrderlines: OrderlineDetails[] = []
  ): Promise<OrderlineDetails[]> {
    try {
      let { shoppingCart } = state;
      let orderlinesToCalculate = [...shoppingCart.Orderlines, ...additionalOrderlines];
      shoppingCart.Orderlines = await CalculatePrice(orderlinesToCalculate);
      commit('setShoppingCart', shoppingCart);
      return shoppingCart.Orderlines;
    } catch (err) {
      return Promise.reject();
    }
  },
  async RemoveFromShoppingCart(
    { dispatch, state, commit },
    {
      orderlineId,
      request,
      shouldRemoveFromDb = true
    }: {
      orderlineId: string;
      request: AmazonRequest;
      shouldRemoveFromDb: boolean;
    }
  ): Promise<any> {
    const promise = new Promise<void>(async (resolve, reject) => {
      try {
        if (!shouldRemoveFromDb) {
          let { shoppingCart } = state;
          shoppingCart.Orderlines = shoppingCart.Orderlines.filter(
            ol => ol.Id !== orderlineId
          );
          console.log('set Shopping cart from delete');
          commit('setShoppingCart', shoppingCart);
          resolve();
        } else {
          SetSnackBar('Deleting item from cart...');
          await OrdersService.DeleteOrderline({
            Id: orderlineId,
            HasCompletedRequest: !!request
          });
          await dispatch('GetShoppingCart');
          SetSnackBar('Deleted from cart successfully');
          resolve();
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
    return promise;
  }
};
