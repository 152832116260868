import { MutationTree } from 'vuex';
import { InvoicesState, Invoice, InvoiceFilterType } from '@/store/models/InvoiceModel';
export const mutations: MutationTree<InvoicesState> = {
  setInvoicesLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setCurrentPage(state, payload: number) {
    state.currentPage = payload;
  },
  setHasInvoices(state, payload: boolean) {
    state.hasInvoices = payload;
  },
  setPaginationState(state, payload: boolean) {
    state.isDonePaginating = payload;
  },
  setFilteredInvoices(state, payload: Invoice[]) {
    state.filteredInvoices = payload;
  },
  setInvoices(state, payload: Invoice[]) {
    state.invoices = payload;
  },
  setFilteringType(state, payload: InvoiceFilterType) {
    state.filteringType = payload;
  },
  setHasFilteredInvoices(state, payload: boolean) {
    state.hasFilteredInvoices = payload;
  }
};
