import { VarDataDynamicTextTypes } from '@/ConstValues';
import { Country } from './AddressModel';

export class Item {
  TypeName: string = 'Item';
  Id!: string;
  PublicId!: string;
  HasSpotVarnish: boolean;
  IsApproved!: boolean;
  PartNumber!: string;
  IsFirstRun!: boolean;
  IsEnabled!: boolean;
  IsSelected: boolean;
  IsTransparency!: boolean;
  BarcodePlacementId: number;
  BarcodeTypeId: number;
  Color!: Color;
  Die!: Die;
  HasBarcode: boolean;
  HasVarnish: boolean;
  Material!: Material;
  Finish!: Finish;
  ApprovalStatus!: string;
  Description!: string;
  LastUpdated!: Date;
  CanDelete!: boolean;
  ModifiedDateFull!: string;
  NeedsBarcodePlacement: boolean;
  ApprovalDate!: string;
  LastOrdered!: Date;
  IsOnOrder!: boolean;
  IsStaticItem!: boolean;
  StaticItemType!: number;
  HasProofOnOrder!: boolean;
  HasBeenProcessed!: boolean;
  Page!: number;
  Version!: number;
  StreamData!: string;
  constructor(rawItem?: any) {
    this.Die = new Die();
    this.PartNumber = '';
    this.IsSelected = false;
    this.Populate(rawItem);
  }
  Populate = (rawItem?: any) => {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.PublicId = rawItem.PublicId;
      this.PartNumber = rawItem.PartNumber;
      this.IsFirstRun = rawItem.IsFirstRun;
      this.IsEnabled = rawItem.IsEnabled;
      this.BarcodePlacementId = rawItem.BarcodePlacementId;
      this.BarcodeTypeId = rawItem.BarcodeType?.BarcodeTypeId;
      this.Color = new Color(rawItem.Color);
      this.Die = new Die(rawItem.Die);
      this.HasSpotVarnish = rawItem.HasSpotVarnish;
      this.HasBarcode = rawItem.HasBarcode;
      this.HasVarnish = rawItem.HasVarnish;
      this.Material = new Material(rawItem.Material);
      this.Finish = new Finish(rawItem.Finish);
      this.ApprovalStatus = rawItem.ApprovalStatus;
      this.IsApproved = rawItem.IsApproved;
      this.IsStaticItem = rawItem.IsStaticItem;
      this.IsTransparency = rawItem.IsTransparency;
      this.StaticItemType = rawItem.StaticItemType;
      this.Description = rawItem.Description;
      this.LastUpdated = rawItem.LastUpdated;
      this.LastOrdered = rawItem.LastOrdered;
      this.ModifiedDateFull = rawItem.ModifiedDateFull;
      this.NeedsBarcodePlacement = rawItem.NeedsBarcodePlacement;
      this.ApprovalDate = rawItem.ApprovalDate;
      this.IsOnOrder = rawItem.IsOnOrder;
      this.HasProofOnOrder = rawItem.hasProofOnOrder;
      this.HasBeenProcessed = rawItem.HasBeenProcessed;
      this.Page = rawItem.Page;
      this.Version = rawItem.Version;
      this.CanDelete = rawItem.CanDelete;
      this.StreamData = rawItem.StreamData;
    }
  };
}

export class ItemDetails extends Item {
  IsVisible!: boolean;
  AuthenticationStatus!: Boolean;
  RollDirection!: RollDirection;
  HashTags!: HashTags[];
  AdditionalHitsOfWhite!: number;
  ShouldConvertFontsToOutline!: boolean;
  Brand!: Brand;
  BrandId!: string;
  CanChangePartNumber!: boolean;
  HasChanged: boolean = false;
  State!: ItemState;
  Gtin!: string;
  SKU!: string;
  ASIN!: string;
  FNSKU!: string;
  IsNew!: boolean;
  GtinType!: GtinType;
  HumanReadable!: HumanReadable;
  ManufacturingName!: string;
  ManufacturingCountry!: Country;
  ManufacturingCity!: string;
  ManufacturingState!: string;
  ManufacturingProvince!: string;
  ManufacturedCountryTextType!: number;
  ItemFile!: any;
  ShouldEnablePantonesForProofs!: boolean;
  constructor(rawItem?: any) {
    super(rawItem);
    this.State = ItemState.New;
    this.ManufacturingCountry = new Country();
    this.PopulateDetails(rawItem);
  }

  PopulateDetails = (rawItem: any) => {
    if (rawItem) {
      this.RollDirection = new RollDirection(rawItem.RollDirection);
      this.HashTags = rawItem.HashTags;
      this.AdditionalHitsOfWhite = rawItem.AdditionalHitsOfWhite;
      this.ShouldConvertFontsToOutline = rawItem.ShouldConvertFontsToOutline;
      this.CanChangePartNumber = rawItem.CanChangePartNumber;
      this.State = ItemState.Edit;
      this.ItemFile = rawItem.ItemFile;
      this.Gtin = rawItem.Gtin;
      this.FNSKU = rawItem.Fnsku;
      this.IsNew = rawItem.IsNew;
      this.SKU = rawItem.Sku;
      this.ASIN = rawItem.Asin;
      this.GtinType = new GtinType({ Id: rawItem.GtinToUse });
      this.Brand = new Brand(rawItem.Brand);
      this.ManufacturingCity = rawItem.ManufacturerCity;
      this.ManufacturingState = rawItem.ManufacturingState;
      this.ManufacturingProvince = rawItem.ManufacturingProvince;
      this.ManufacturedCountryTextType = rawItem.ManufacturedCountryTextType;
      this.HumanReadable = new HumanReadable({ Type: rawItem.HumanReadable });
      this.ManufacturingName = rawItem.ManufacturingName;
      this.ManufacturingCountry = new Country({
        CountryValue: rawItem.ManufacturingCountry
      });
      this.HasBarcode = rawItem.HasBarcode;
      this.ShouldEnablePantonesForProofs = rawItem.ShouldEnablePantonesForProofs;
    }
  };
}
export class HumanReadable {
  Id: VarDataDynamicTextTypes = VarDataDynamicTextTypes.None;
  Type!: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Type = rawItem.Type;
    }
  }
}

export enum ItemState {
  New = 0,
  Edit = 1
}
export class ItemInfoDisplay {
  PartNumber!: string;
  Description!: string;
  Status!: string;
  Material!: string;
  Die!: string;
  Finish!: string;
  Color!: number;
  IsApproved!: boolean;
  LastUpdated!: string;
  LastOrdered!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.PartNumber = rawItem.PartNumber;
      this.Description = rawItem.Description;
      this.Status = rawItem.ApprovalStatus;
      this.IsApproved = rawItem.IsApproved;
      this.Material = rawItem.Material.BadgeText;
      this.Die = rawItem.DieInformation;
      this.Finish = rawItem.Finish.BadgeText;
      this.Color = rawItem.Color.ClickColors;
      this.LastUpdated = rawItem.LastUpdated;
      this.LastOrdered = rawItem.LastOrdered;
    }
  }
}

export class LatestEntry {
  Entry!: string;
  EntryDateTime!: string;
  UserName!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Entry = rawItem.Entry;
      this.EntryDateTime = rawItem.EntryDateTime;
      this.UserName = rawItem.UserName;
    }
  }
}

export class ItemInfo {
  Materials: Material[] = [new Material()];
  Lamination: Finish[] = [new Finish()];
  Colors: Color[] = [new Color()];
  RollDirections: RollDirection[] = [new RollDirection()];
  Brands: Brand[] = [new Brand()];
  HumanReadables: string[] = [''];
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Materials = rawItem.materials;
      this.Lamination = rawItem.lamination;
      this.Colors = rawItem.colors;
      this.HumanReadables = rawItem.humanReadables;
      this.RollDirections = rawItem.rollDirections;
      this.Brands = rawItem.brands;
    }
  }
}

export class Lamination {
  Id!: number;
  ShortDesc!: string;
  LongDesc!: string;
  CustomerDesc!: string;
  constructor(rawItem?: any) {
    this.Id = 0;
    this.ShortDesc = '';
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.ShortDesc = rawItem.ShortDesc;
      this.LongDesc = rawItem.LongDescription;
      this.CustomerDesc = rawItem.CustomerDesc;
    }
  }
}

export class Brand {
  Name: string = '';
  Id!: number;
  BrandId: string = '';
  HasBrandLogo!: boolean;
  ShouldGenerate1Up!: boolean;
  ShouldRequestCodes!: boolean;
  Src!: string;
  BrandLogoFileName!: string;
  OriginalFileName!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Name = rawItem.BrandName;
      this.Id = rawItem.Id;
      this.HasBrandLogo = rawItem.HasBrandLogo;
      this.ShouldGenerate1Up = rawItem.ShouldGenerate1Up;
      this.Src = rawItem.Src;
      this.BrandId = rawItem.BrandId;
      this.BrandLogoFileName = rawItem.BrandLogoFileName;
      this.OriginalFileName = rawItem.OriginalFileName;
    }
    this.ShouldRequestCodes = !!this.BrandId;
  }
}
export class RollDirection {
  Id!: number | string;
  Direction!: string;
  Position!: number;
  ShortDesc!: string;
  Display!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Direction = rawItem.Direction;
      this.Position = rawItem.Position;
      this.ShortDesc = rawItem.ShortDescription;
      this.Display = rawItem.Display;
    }
  }
}

export class Color {
  Id!: number;
  ClickColors!: number;
  Description!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.ClickColors = rawItem.ClickColors;
      this.Description = rawItem.Description;
    }
  }
}

export class Die {
  Id!: number;
  Description!: string;
  Tag!: string;
  Height!: string | null;
  BleedHeight!: number;
  Width!: string | null;
  BleedWidth!: number;
  Shape!: string;
  NumberAcross!: number;
  CornerRadius!: number;
  Cylinder!: Cylinder;
  IsOnOrder!: boolean;
  IsInStock!: boolean;
  SVGTrimLineRaster: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Description = rawItem.Description;
      this.Tag = rawItem.Tag;
      this.Height = rawItem.Height;
      this.BleedHeight = rawItem.BleedHeight;
      this.Width = rawItem.Width;
      this.BleedWidth = rawItem.BleedWidth;
      this.Shape = rawItem.Shape;
      this.NumberAcross = rawItem.NumberAcross;
      this.Cylinder = new Cylinder(rawItem.Cylinder);
      this.IsOnOrder = rawItem.IsOnOrder;
      this.IsInStock = rawItem.IsInStock;
      this.SVGTrimLineRaster = rawItem.SVGTrimLineRaster;
    }
  }
}
export class Cylinder {
  Id!: number;
  Tooth!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Tooth = rawItem.Tooth;
    }
  }
}

export class Material {
  Id!: number;
  BadgeText!: string;
  ShortDescription!: string;
  CustomerDesc!: string;
  CanUseWhite!: boolean;
  constructor(rawItem?: any) {
    this.Id = 0;
    this.ShortDescription = '';
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.BadgeText = rawItem.BadgeText;
      this.ShortDescription = rawItem.ShortDesc;
      this.CustomerDesc = rawItem.CustomerDesc;
      this.CanUseWhite = rawItem.CanUseWhite;
    }
  }
}

export class Finish {
  Id!: number;
  BadgeText!: string;
  ShortDesc!: string;
  LongDesc!: string;
  CustomerDesc!: string;
  constructor(rawItem?: any) {
    this.BadgeText = '';
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.BadgeText = rawItem.BadgeText;
    }
  }
}
export class HashTags {
  Id!: number;
  HashTagValue!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.HashTagValue = rawItem.HashTagValue;
    }
  }
}

export class ItemHistory {
  DateTime!: string;
  Entry!: string;
  User!: string;
  constructor(rawItem: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem?: any) {
    if (rawItem) {
      this.DateTime = rawItem.EntryDateTime;
      this.Entry = rawItem.Entry;
      this.User = rawItem.UserName;
    }
  }
}
export class GtinType {
  Name!: string;
  Id!: number;
  CharLength!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Name = rawItem.BrandName;
      this.Id = rawItem.Id;
      this.CharLength = rawItem.CharLength;
    }
  }
}

export enum ItemFilterType {
  Everything,
  OnOrder,
  OnHold,
  SignOffs
}

export interface ItemsState {
  items: Item[];
  queuedItems: Item[];
  partNumbers: {
    PartNumber: string;
    Page: number;
    Description: string;
    Id: string;
    Version: number;
  }[];
  filteredItem: Item[];
  filteringType: ItemFilterType;
  posAssociatedWithItem: string[];
  itemFilterCountContainer: number[];
  filteredPartNumber: any[];
  processingImageItemIds: string[];
  itemInfo: ItemInfo;
  currentPage: number;
  shouldReconcileQueuedItems: boolean;
  hasItems: boolean;
  errorLoadingItems: boolean;
  isLoading: boolean;
  isDonePaginating: boolean;
}
