import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { User } from '@/store/models/ProfileModel';
import AdminService from '@/services/admin-service';

const authUrl = `${process.env.VUE_APP_AUTH_API}`;
const resourceUrl = `${process.env.VUE_APP_ROOT_API}`;

export const AuthService = {
  async login(
    model: FormData,
    store: any,
    shouldgetDisplayName: boolean = true
  ): Promise<AxiosResponse> {
    let username = model.get('userName') as string;
    let password = model.get('Password') as string;
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post(
          `${authUrl}/authenticate/`,
          { username, password },
          requestOptions
        );
        let token = handleResponse(response);
        if (token) {
          const user = new User(token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.sessionId}`;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (shouldgetDisplayName) {
            let displayNameResponse = await this.getDisplayName();
            if (displayNameResponse.data) user.displayName = displayNameResponse.data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('formatVersion', '1');
            AdminService.addActivityStream('Logged in - Main Site');
          }
          return resolve(token);
        }
      } catch (error) {
        return reject(error);
      }
    });
  },
  async resetPasswordWithToken(model: any) {
    return axios.post(`${authUrl}/ResetPasswordWithToken`, model);
  },
  async changePassword(model: FormData) {
    const username = model.get('Username') as string;
    const password = model.get('Password') as string;
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    try {
      const response = await axios.post(
        `${authUrl}/ChangePassword`,
        { username, password },
        requestOptions
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createUserForAdmin(model: FormData) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    const username = model.get('Email') as string;
    const password = model.get('Password') as string;
    try {
      await axios.post(`${authUrl}/CreateUser`, { username, password }, requestOptions);
    } catch (err) {
      throw err;
    }
  },
  async createUser(
    model: FormData,
    shouldLogin: boolean = true,
    shouldCreateDbUser = true
  ) {
    const username = model.get('Email') as string;
    const password = model.get('Password') as string;
    const firstName = model.get('FirstName') as string;
    const lastName = model.get('LastName') as string;
    const companyName = model.get('CompanyName') as string;

    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    const newUser = {
      IsConfirmed: true,
      FirstName: firstName,
      LastName: lastName,
      UserName: username,
      CompanyName: companyName
    };
    try {
      await axios.post(`${authUrl}/CreateUser`, { username, password }, requestOptions);
      if (shouldLogin) await this.login(model, null, false);
    } catch (err) {
      throw err;
    }
    try {
      if (shouldCreateDbUser)
        await axios.post(`${resourceUrl}/account/CreateUser`, newUser, requestOptions);
    } catch (err) {
      await axios.post(`${authUrl}/Rollback`, { username }, requestOptions);
      throw err;
    }
  },
  async lockoutUser(username: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    try {
      const response = await axios.post(
        `${authUrl}/LockoutUser`,
        { username },
        requestOptions
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  async unlockUser(username: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    try {
      const response = await axios.post(
        `${authUrl}/UnlockUser`,
        { username },
        requestOptions
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  async isUserLockedout(username: string) {
    return await axios.get(`${authUrl}/IsUserLockedOut?userName=${username}`);
  },
  async changeUserName(oldUserName: string, newUserName: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    let model = { CurrentUserName: oldUserName, NewUserName: newUserName };
    try {
      const response = await axios.post(
        `${authUrl}/ChangeUserName`,
        model,
        requestOptions
      );
    } catch (err) {
      throw err;
    }
  },
  doesUserExist(username: string) {
    return axios.get(`${resourceUrl}/account/DoesUserExist?username=${username}`);
  },
  doesCompanyNameExist(companyName: string) {
    return axios.get(
      `${resourceUrl}/account/DoesCompanyNameExist?companyName=${companyName}`
    );
  },
  async isAdmin() {
    return await axios.get(`${resourceUrl}/account/IA`);
  },
  async isConfirmed() {
    return await axios.get(`${resourceUrl}/account/IC`);
  },
  async getDisplayName() {
    return await axios.get(`${resourceUrl}/account/GetDisplayName`);
  },
  async hasAnIdentityAccount(username: string) {
    return await axios.get(`${authUrl}/HasUserAnIdentityAccount?username=${username}`);
  },
  GetCurrentUser() {
    return axios.get(`${resourceUrl}/account/GetCurrentUser`);
  },
  GetUserInfo() {
    return axios.get(`${resourceUrl}/account/GetUserInfo`);
  },
  ResendConfirmationEmail(userId: string) {
    return axios.get(`${resourceUrl}/account/ResendConfirmationEmail?userId=${userId}`);
  },
  SetFirstName(firstName: string) {
    let model = {
      firstName: firstName
    };
    return axios.post(`${resourceUrl}/account/SetFirstName?firstName=${firstName}`);
  },
  SetLastName(lastName: string) {
    let model = {
      StringValue: lastName
    };
    return axios.post(`${resourceUrl}/account/SetLastName`, model);
  },
  SendPasswordResetEmail(Email: string) {
    const model = {
      Email
    };
    return axios.post(`${authUrl}/SendPasswordResetToken`, model);
  }
};

function handleResponse(response: any) {
  const data = response.data;
  if (response.status !== 200) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      // logout();
      location.reload();
    }

    const error = (data && data.message) || response.statusText;
    throw error;
  }
  return data;
}

export default AuthService;
