export class DieTemplate {
  Id!: number;
  PublicDescription!: string;
  Tag!: string;
  TagName!: string;
  Height!: string;
  HeightValue!: number;
  Width!: string;
  WidthValue!: number;
  Size!: string;
  Shape!: string;
  CornerRadius!: string;
  SVGTrimLIneRaster!: string;
  IsAdvancedDie!: boolean;
  IsInStock!: boolean;
  IsAutoGenerated!: boolean;
  IsExactMatch!: boolean;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any): void {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Tag = rawItem.Tag;
      this.TagName = rawItem.TagName;
      this.Height = rawItem.Height;
      this.HeightValue = rawItem.HeightValue;
      this.Width = rawItem.Width;
      this.WidthValue = rawItem.WidthValue;
      this.Size = rawItem.Size;
      this.Shape = rawItem.Shape;
      this.CornerRadius = rawItem.CornerRadius;
      this.SVGTrimLIneRaster = rawItem.SVGTrimLIneRaster;
      this.IsAdvancedDie = rawItem.IsAdvancedDie;
      this.IsInStock = rawItem.IsInStock;
      this.IsAutoGenerated = rawItem.IsAutoGenerated;
      this.IsExactMatch = rawItem.IsExactMatch;
    }
  }
}

export class DieRequestModel {
  Shape!: string;
  Height!: string;
  Width!: string;
  CornerRadius!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any): void {
    if (rawItem) {
      this.Shape = rawItem.Shape;
      this.Height = rawItem.Height;
      this.Width = rawItem.Width;
      this.CornerRadius = rawItem.CornerRadius;
    }
  }
}
export class DieResponse {
  Id!: number;
  Tag!: string;
  TagName!: string;
  Height!: number;
  Width!: number;
  CornerRadius!: number;
  Shape!: string;
  IsInStock!: boolean;
  IsAutoGenerated!: boolean;
  IsExactMatch!: boolean;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any): void {
    if (rawItem) {
      this.Tag = rawItem.Tag;
      this.TagName = rawItem.TagName;
      this.Height = rawItem.Height;
      this.Width = rawItem.Width;
      this.CornerRadius = rawItem.CornerRadius;
      this.Shape = rawItem.Shape;
      this.IsInStock = rawItem.IsInStock;
      this.IsAutoGenerated = rawItem.IsAutoGenerated;
      this.IsExactMatch = rawItem.IsExactMatch;
    }
  }
}

export interface DieState {
  dieTemplates: DieTemplate[];
  error: boolean;
  isLoading: boolean;
}
