import { Country, Email, PhoneNumber } from './AddressModel';

export class CompanyEmails {
  InvoiceNotificationsEmails!: string[];
  ShippingNotificationEmails!: string[];
  OrderNotificationEmails!: string[];
  EmailType!: EmailNotificationTypes;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Populate(rawItem);
    }
  }
  Populate(rawItem: any) {
    this.InvoiceNotificationsEmails = rawItem.InvoiceNotificationEmails;
    this.ShippingNotificationEmails = rawItem.ShippingNotifications;
    this.OrderNotificationEmails = rawItem.OrderNotificationEmails;
    this.EmailType = rawItem.EmailType;
  }
}
export class CompanyModel {
  Id!: string;
  CompanyName!: string;
  AddressLine1!: string;
  AddressLine2!: string;
  City!: string;
  State!: string;
  Country!: string;
  CountryId!: number;
  Province!: string;
  Zipcode!: string;
  PrimaryContact!: string;
  PhoneNumber!: PhoneNumber;
  PrimaryEmail!: Email;
  InvoiceNotificationsEmails!: string[];
  AmazonAuthenticationEmails!: string[];
  OrderNotificationEmails!: string[];
  constructor(rawItem: any) {
    if (rawItem) {
      this.Populate(rawItem);
    } else {
      this.PhoneNumber = new PhoneNumber();
      this.PrimaryEmail = new Email();
    }
  }
  Populate(rawItem: any) {
    this.Id = rawItem.Id;
    this.CompanyName = rawItem.Name;
    this.AddressLine1 = rawItem.BillingAddress.Street1;
    this.AddressLine2 = rawItem.BillingAddress.Street2;
    this.City = rawItem.BillingAddress.City;
    this.State = rawItem.BillingAddress.State;
    this.Country = rawItem.BillingAddress.Country;
    this.CountryId = rawItem.BillingAddress.CountryId;
    this.Province = rawItem.BillingAddress.Province;
    this.Zipcode = rawItem.BillingAddress.ZipCode;
    this.PrimaryContact = rawItem.Contact;
    this.PhoneNumber = new PhoneNumber(rawItem.Phone);
    this.PrimaryEmail = new Email(rawItem.ContactEmail);
    this.InvoiceNotificationsEmails = rawItem.InvoiceNotificationEmails;
    this.AmazonAuthenticationEmails = rawItem.AmazonAuthenticationEmails;
    this.OrderNotificationEmails = rawItem.OrderNotificationEmails;
  }
}

export class ContactModel {
  EmailAddress!: string;
  EmailId!: string;
  PhoneId!: string;
  PhoneNumber!: string;
  ContactName!: string;
  Brand!: number;
  constructor(rawItem: any) {
    if (rawItem) {
      this.Populate(rawItem);
    }
  }
  Populate(rawItem: any) {
    this.EmailAddress = rawItem.EmailAddress;
    this.PhoneNumber = rawItem.PhoneNumber;
    this.ContactName = rawItem.ContactName;
    this.PhoneId = rawItem.PhoneId;
    this.Brand = rawItem.Brand;
  }
}

export enum EmailNotificationTypes {
  Invoice = 1,
  OrderPlaced,
  Shipping
}
