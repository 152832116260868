import { Item } from './ItemModel';

export class QuickDialog {
  Headertext!: string;
  Headericon!: string;
  SubheaderPrimary!: string;
  SubheaderSecondary!: string;
  Item!: Item;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.SubheaderPrimary = rawItem.SubheaderPrimary;
      this.SubheaderSecondary = rawItem.SubheaderSecondary;
      this.Item = new Item(rawItem.Item);
    }
  }
}

export class AlertModel {
  Type!: string;
  Title!: string;
  Message!: string;
  SecondaryMessage!: string;
  ConfirmationMessage!: string;
  IsCancelBtnDisabled: boolean = false;
  List!: any;
  ItemId!: string | null;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Type = rawItem.Type;
      this.Title = rawItem.Title;
      this.Message = rawItem.Message;
      this.SecondaryMessage = rawItem.SecondaryMessage;
      this.ConfirmationMessage = rawItem.ConfirmationMessage;
      this.IsCancelBtnDisabled = rawItem.IsCancelBtnDisabled;
      this.List = rawItem.List;
      this.ItemId = rawItem.ItemId;
    }
  }
}
