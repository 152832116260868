import { MutationTree } from 'vuex';
import { ItemsState, Item, ItemInfo } from '../../models/ItemModel';
export const mutations: MutationTree<ItemsState> = {
  areItemsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  itemsLoaded(state, payload: Item[]) {
    state.items = payload;
  },
  itemInfoLoaded(state, payload: ItemInfo) {
    state.itemInfo = payload;
  },
  setQueuedItems(state, payload: Item[]) {
    state.queuedItems = payload;
  },
  setFilteredItem(state, payload: Item[]) {
    state.filteredItem = payload;
  },
  setFilteringType(state, payload: number) {
    state.filteringType = payload;
  },
  shouldReconcileQueuedItems(state, payload: boolean) {
    state.shouldReconcileQueuedItems = payload;
  },
  setCurrentPage(state, payload: number) {
    state.currentPage = payload;
  },
  setPaginationState(state, payload: boolean) {
    state.isDonePaginating = payload;
  },
  setHasItems(state, payload: boolean) {
    state.hasItems = payload;
  },
  setPartNumbers(
    state,
    payload: {
      Page: number;
      PartNumber: string;
      Description: string;
      Id: string;
      Version: number;
    }[]
  ) {
    state.partNumbers = payload;
  },

  setFilteredPartNumber(state, payload) {
    state.filteredPartNumber = payload;
  },
  setItemFilterCountContainer(state, payload) {
    state.itemFilterCountContainer = payload;
  },
  saveImageData(state, payload: { Id: string; Stream: string }) {
    let item = state.items.find(i => i.Id === payload.Id);
    if (item) {
      item.StreamData = payload.Stream;
    }
  },
  setErrorLoadingItems(state, payload: boolean) {
    state.errorLoadingItems = payload;
  },
  setProcessingImageItemId(state, payload: string[]) {
    state.processingImageItemIds = payload;
  },
  setOrderlinesAssociatedWithItem(state, payload: string[]) {
    state.posAssociatedWithItem = payload;
  }
};
