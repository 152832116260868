export class KeyFrameDynamicValues {
  DistanceX!: number;
  DistanceY!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem?: any) {
    if (rawItem) {
      this.DistanceX = rawItem.distanceX;
      this.DistanceY = rawItem.distanceY;
    }
  }
}
